import React, { useState } from 'react';
import { 
    Avatar, 
    Button, 
    CssBaseline, 
    TextField,
    Link, 
    Box, 
    Typography,
    Alert,
    IconButton, 
    InputAdornment,
    CircularProgress,
    Grid2, 
} from '@mui/material';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import Cookies from 'js-cookie'; 
import { constants } from '../auth/_constants/constants';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import LanguageSwitcher from '../lang/components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { getUserAdress } from '../reduxAction/user.action';
import { getKids } from '../reduxAction/kids.action';
import Copyright from '../components/functions/Functions';

export default function Login({ setIsAuthenticated }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [resendEnabled, setResendEnabled] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Utilisation du contexte pour le thème
    // const { mode, toggleTheme } = useContext(ThemeModeContext);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
        setAlertMessage('');
        if (!validateEmail(value)) {
            setEmailError(t('login.invalidEmail'));
        } else {
            setEmailError('');
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setAlertMessage('');
    };

    const handleResend = async () => {
        navigate('/resend-verification');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateEmail(email) || password.trim() === '') {
            setAlertMessage(t('login.validationError'));
            setAlertSeverity('error');
            return;
        }
    
        const userData = {
            email,
            password,
        };

        try {
            setLoading(true);
            const response = await api.post('auth/login', userData);
    
            if (!response.data.access_token || !response.data.refresh_token) {
                throw new Error('Invalid tokens');
            }
    
            const { access_token, refresh_token, user, redirect_to } = response.data;
    
            dispatch({ type: constants.LOGIN_SUCCESS, payload: response.data });
            setAlertMessage(response.data.message || t('login.loginSuccess'));
            setAlertSeverity('success');
    
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('user', JSON.stringify(user));
            Cookies.set('refresh_token', refresh_token, {
                expires: 15,
                secure: true,
                sameSite: 'Strict',
            });
    
            setIsAuthenticated(true);
    
            dispatch(getUserAdress());
            dispatch(getKids());
    
            setTimeout(() => {
                navigate(redirect_to);
            }, 1000);
        } catch (error) {
            const errorResponse = error.response?.data || {};
            const errorMessage = errorResponse.message || t('login.loginError');
            setAlertSeverity('error');
            setAlertMessage(errorMessage);
    
            if (errorResponse.linkReSent) {
                setResendEnabled(true);
            } else {
                setResendEnabled(false);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid2 container component="main" sx={{ height: '100vh' }}>
                <Grid2 
                    xs={12}
                    md={4}
                    elevation={6} 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mx: 'auto',
                        maxWidth: '400px',
                        width: '100%',
                    }}
                >
                    {/* Bouton switch pour changer de thème */}
                    {/* <Box sx={{ 
                        width: '100%', 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        mb: 2 
                    }}>
                        <Brightness7Icon sx={{ mr: 1 }} />
                        <FormControlLabel
                            control={
                                <Switch 
                                  checked={mode === 'dark'} 
                                  onChange={toggleTheme} 
                                  color="default" 
                                />
                            }
                            label=""
                        />
                        <Brightness4Icon sx={{ ml: 1 }} />
                    </Box> */}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            p: 4,
                        }}
                    >
                        {alertMessage && (
                            <Alert  severity={alertSeverity} sx={{ mb: 1, width: '100%' }}>
                                {alertMessage}
                                {resendEnabled && (
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        <Link
                                            href="#"
                                            onClick={handleResend}
                                            underline="hover"
                                            sx={{ fontWeight: 'bold', color:'white' }}
                                        >
                                            {t('login.resendVerification')}
                                        </Link>{' '}
                                        {t('login.resendVerificationPrompt')}
                                    </Typography>
                                )}
                            </Alert>
                        )}
                        <Avatar sx={{ m: 1, bgcolor: 'transparent', width: 56, height: 56 }}>
                            <img 
                                src="https://www.i-kiddy.com/images/i-kiddy-logo.png" 
                                alt="Logo" 
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
                            />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t('login.welcome')}
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('login.emailAddress')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                size="small"
                                value={email}
                                onChange={handleEmailChange}
                                error={!!emailError}
                                helperText={emailError}
                                slotProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('login.password')}
                                type={showPassword ? 'text' : 'password'} 
                                id="password"
                                autoComplete="current-password"
                                size="small"
                                value={password}
                                onChange={handlePasswordChange}
                                slotProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOutlined />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                          
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 1.2 }}
                                disabled={!email || !password || !!emailError || loading}
                            >
                                {loading ? <CircularProgress size={24} /> : t('login.signIn')}
                            </Button>
                            <Grid2 container sx={{ justifyContent: 'space-between' }}>
                                <Grid2>
                                    <Link href="/forget-password" variant="body2" sx={{ textDecoration: 'none', fontSize:"13px" }}>
                                        {t('login.forgotPassword')}
                                    </Link>
                                </Grid2>
                                <Grid2>
                                    <Link href="/register" variant="body2" sx={{ textDecoration: 'none', fontSize:"13px"  }}>
                                        {t('login.dontHaveAccount')}
                                    </Link>
                                </Grid2>
                            </Grid2>
                        </Box>
                        <LanguageSwitcher/>
                        <Box sx={{ mt: 5, mb: 2 }}>
                            <Copyright />
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </Box>
    );
}