import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Alert,
  CircularProgress,
  Card,
} from "@mui/material";
import api from "../utils/api";
import { constants } from "../auth/_constants/constants";
import { useDispatch, useSelector } from "react-redux";
import PhoneNumberInput from "./PhoneNumberInput";
import SearchAddress from "./SearchAddress";

const AddressInfo = () => {
  const adress = useSelector((state) => state.user.adress); // Récupérer l'adresse depuis Redux
  const [address, setAddress] = useState({
    number: "",
    fullAddress: "",
    details: null,
  });
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // État pour gérer le bouton et le chargement
  const [canSubmit, setCanSubmit] = useState(false); // État pour réactiver le bouton après modification
  const dispatch = useDispatch();
  const wrapperRef = useRef(null); // Référence pour détecter les clics en dehors

  // 📌 Sauvegarde de l'adresse sélectionnée
  const handleSaveAddress = async () => {
    if (!address.details) {
      setMessage("Aucune adresse sélectionnée à enregistrer.");
      return;
    }

    const userData = {
      streetNumber: address.details.streetNumber || "",
      streetName: address.details.streetName || "",
      city: address.details.city || "",
      postalCode: address.details.postalCode || "",
      country: address.details.country || "",
      latitude: address.details.latitude || null,
      longitude: address.details.longitude || null,
    };

    setIsSubmitting(true);
    setCanSubmit(false);
    setMessage("");

    try {
      const response = await api.post("/update-user-adress", userData);

      if (response.status === 200) {
        setMessage("Adresse enregistrée avec succès.");

        dispatch({
          type: constants.UPDATE_USER_ADRESS,
          payload: {
            adress: userData,
          },
        });
      } else {
        setMessage("Une erreur est survenue lors de l’enregistrement.");
      }
    } catch (error) {
      setMessage("Erreur de communication avec le serveur.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // 📌 Mettre à jour l'adresse sélectionnée et activer le bouton
  const handleAddressSelect = (selectedAddress) => {
    setAddress({
      number: selectedAddress.details.streetNumber || "",
      fullAddress: selectedAddress.fullAddress,
      details: selectedAddress.details,
    });

    setCanSubmit(true); // Activation du bouton "Enregistrer l'adresse"
  };

  return (
    <Card sx={{ padding:"20px", boxShadow: 1 }}>

    <Box ref={wrapperRef} sx={{ position: "relative", width: "100%" }}>
      {/* 📌 Champ de numéro de téléphone */}
      <PhoneNumberInput defaultPhoneNumber={adress?.phoneNumber || ""} />

      {/* 📌 Champ de recherche d'adresse */}
      <SearchAddress defaultAddress={adress} handleAddressSelect={handleAddressSelect} />

      {/* 📌 Message de succès ou d'erreur */}
      {message && (
        <Alert severity={message.includes("succès") ? "success" : "error"} sx={{ mt: 2 }}>
          {message}
        </Alert>
      )}

      {/* 📌 Bouton d'enregistrement */}
      <Button
        variant="contained"
        onClick={handleSaveAddress}
        disabled={isSubmitting || !canSubmit}
        fullWidth
        sx={{ mt: 2 }}
      >
        {isSubmitting ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Enregistrer l'adresse"}
      </Button>
    </Box>
    </Card>
  );
};

export default AddressInfo;