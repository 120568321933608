import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PublicRoute from '../auth/PublicRoute';
import PrivateRoute from '../auth/PrivateRoute';
import Login from '../pages/Login';
import ForgetPassword from '../pages/ForgetPassword';
import VerifyEmail from '../pages/VerifyEmail';
import ResendVerificationEmail from '../pages/ResendVerificationEmail';
import NotFound from '../pages/NotFound';
import NewPassword from '../pages/NewPassword';
import RegistrationContenair from '../pages/RegistrationContenair';
import AdminDashboard from '../pages/admin/AdminDashboard';
import ParentDashboard from '../pages/parents/ParentDashboard';
import OrganizerDashboard from '../pages/organizers/OrganizerDashboard';
import ConfirmEmailChange from '../pages/ConfirmEmailChange';

const Routers = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (token && user) {
            setIsAuthenticated(true);
            setUserRole(user.role); // Définir le rôle de l'utilisateur
        } else {
            setIsAuthenticated(false);
            setUserRole(null);
        }
    }, []); // Dépendances vides pour éviter les boucles infinies

    return (
        <BrowserRouter
            future={{
                v7_startTransition: true, // Activer l'optimisation React.startTransition
                v7_relativeSplatPath: true, // Changement de la gestion des chemins relatifs
            }}
        >
            <Routes>
                {/* Routes publiques */}
                <Route
                    path="/"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                        >
                            <Login setIsAuthenticated={setIsAuthenticated} />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/login"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                        >
                            <Login setIsAuthenticated={setIsAuthenticated} />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                        >
                            <RegistrationContenair />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/forget-password"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                        >
                            <ForgetPassword />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/verifyEmail"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                        >
                            <VerifyEmail />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/confirmEmailChange"
                    element={
                        <PublicRoute
                            restricted={false} // Accessible par tout le monde
                            isAuthenticated={isAuthenticated}
                        >
                            <ConfirmEmailChange isAuthenticated={isAuthenticated} />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/newpassWord"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                        >
                            <NewPassword />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/resend-verification"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                        >
                            <ResendVerificationEmail />
                        </PublicRoute>
                    }
                />

                {/* Routes privées pour chaque rôle */}
                <Route
                    path="/parent/:section?/:tab?"
                    element={
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            allowedRoles={['parent']}
                            userRole={userRole}
                        >
                            <ParentDashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/organizer/:section?/:tab?/:tab2?/:tab3?/:tab4?"
                    element={
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            allowedRoles={['organizer']}
                            userRole={userRole}
                        >
                            <OrganizerDashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/admin/dashboard"
                    element={
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            allowedRoles={['admin']}
                            userRole={userRole}
                        >
                            <AdminDashboard />
                        </PrivateRoute>
                    }
                />

                {/* Route par défaut (404) */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Routers;