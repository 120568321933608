import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation(); // Hook pour accéder à la configuration i18n

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Changer la langue
  };

  return (
    <FormControl
      component="fieldset"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2, // Espace entre les éléments
        mt: 2, // Marge supérieure
      }}
    >
      <RadioGroup
        row // Alignement horizontal
        value={i18n.language} // Langue actuellement sélectionnée
        onChange={(e) => changeLanguage(e.target.value)} // Changer de langue
        sx={{
          gap: 1.5, // Espacement entre les options
        }}
      >
        <FormControlLabel
          value="en"
          control={<Radio sx={{ color: '#c2c2c2' }} />}
          label={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '12px', // Taille de la police
              }}
            >
              🇬🇧 En
            </span>
          }
        />
        <FormControlLabel
          value="fr"
          control={<Radio sx={{ color: '#c2c2c2' }} />}
          label={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '12px', // Taille de la police
              }}
            >
              🇫🇷 Fr
            </span>
          }
        />
        <FormControlLabel
          value="nl"
          control={<Radio sx={{ color: '#c2c2c2' }} />}
          label={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '12px', // Taille de la police
              }}
            >
              🇳🇱 Ned
            </span>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default LanguageSwitcher;