import { combineReducers } from 'redux'
import user from './user.reducer'
import kids from './kids.reducer'
import organizers from './organizers.reducer'
import activities from './activities.reducer'

export default combineReducers({
    user,
    kids,
    organizers,
    activities,
})