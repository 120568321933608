import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import ChoiceType from './ChoiceType'; // Component pour le choix du type de compte
import Register from './Register'; // Component pour le formulaire d'inscription

export default function RegistrationTabs() {
    const [activeTab, setActiveTab] = useState(0); // Contrôle des onglets
    const [role, setRole] = useState(null); // Type de compte choisi (parent ou organisateur)

    const handleTabChange = (event, newValue) => {
        if (newValue === 1 && !role) {
            alert("Veuillez d'abord choisir un type de compte !");
            return;
        }
        setActiveTab(newValue);
    };

    const handleChoice = (type) => {
        setRole(type);
        setActiveTab(1); // Activer l'onglet 2 après le choix
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                centered
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                {/* Texte des onglets en minuscule */}
                <Tab 
                    label="Choix du type de compte" 
                    variant="h1"
                    sx={{ textTransform: 'none', fontSize:18 }} // Texte reste tel quel
                />
                <Tab 
                    label="Formulaire d'inscription" 
                    disabled={!role} 
                    sx={{ textTransform: 'none', fontSize:18 }} // Texte reste tel quel
                />
            </Tabs>

            <Box sx={{ padding: 3 }}>
                {activeTab === 0 && (
                    <ChoiceType onChoice={handleChoice} selectedType={role} />
                )}
                {activeTab === 1 && (
                    <Register role={role} />
                )}
            </Box>
        </Box>
    );
}