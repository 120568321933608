import React, { useState } from 'react';
import {
  TextField,
  Box,
  Button,
  CircularProgress,
  Alert,
  Card,
} from '@mui/material';
import api from '../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { constants } from '../auth/_constants/constants';

const UpdateUserName = () => {
  const user = useSelector((state) => state.user.user);
  const [firstName, setFirstName] = useState(user.firstname);
  const [lastName, setLastName] = useState(user.lastname);
  const [initialFirstName, setInitialFirstName] = useState(user.firstname);
  const [initialLastName, setInitialLastName] = useState(user.lastname);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const dispatch = useDispatch();

  // Gestion des champs de formulaire
  const handleFirstNameChange = (event) => {
    const newValue = event.target.value;
    setFirstName(newValue);
    setError('');
    setSuccessMessage('');
    setIsModified(newValue.trim() !== initialFirstName || lastName.trim() !== initialLastName);
  };

  const handleLastNameChange = (event) => {
    const newValue = event.target.value;
    setLastName(newValue);
    setError('');
    setSuccessMessage('');
    setIsModified(firstName.trim() !== initialFirstName || newValue.trim() !== initialLastName);
  };

  // Sauvegarde des modifications via le Back-End
  const handleSave = async () => {
    if (!firstName.trim() || !lastName.trim()) {
      setError('Le prénom et le nom ne peuvent pas être vides.');
      return;
    }
  
    if (firstName.length < 2 || lastName.length < 2) {
      setError('Le prénom et le nom doivent contenir au moins 2 caractères.');
      return;
    }
  
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
  
    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
    };
  
    try {
      const response = await api.post('/update-user-name', data);
  
      if (response.status === 200) {
        setSuccessMessage('Nom et prénom mis à jour avec succès.');
        setInitialFirstName(firstName);
        setInitialLastName(lastName);
  
        dispatch({
          type: constants.UPDATE_USER_NAME,
          payload: {
            firstname: data.firstName,
            lastname: data.lastName,
          },
        });
  
        // Mise à jour locale
        const currentUser = JSON.parse(localStorage.getItem('user'));
        currentUser.firstname = data.firstName;
        currentUser.lastname = data.lastName;
        localStorage.setItem('user', JSON.stringify(currentUser));
  
        setIsModified(false); // Désactiver le bouton après la soumission
      } else {
        setError('Une erreur est survenue lors de la mise à jour.');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Erreur de communication avec le serveur.';
      console.error(errorMessage);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ padding:"40px 20px", boxShadow: 1 }}>
      <Box >
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

        <TextField
          label="Prénom"
          variant="outlined"
          fullWidth
          value={firstName}
          onChange={handleFirstNameChange}
          placeholder="Entrez votre prénom"
          sx={{ mb: 2 }}
        />

        <TextField
          label="Nom"
          variant="outlined"
          fullWidth
          value={lastName}
          onChange={handleLastNameChange}
          placeholder="Entrez votre nom"
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSave}
          disabled={isLoading || !isModified} // Désactiver si non modifié ou en cours de traitement
        >
          {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Enregistrer'}
        </Button>
      </Box>
    </Card>
  );
};

export default UpdateUserName;