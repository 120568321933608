import { constants } from "../auth/_constants/constants";

const initialState = {
    kids: [], // Liste des enfants
    status: false,
    error: null,
};

export default function kidsReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_KIDS: // Récupération des enfants
            return {
                ...state,
                kids: action.payload.children || [], // Récupère la liste des enfants
                status: true,
                error: null, // Réinitialise les erreurs éventuelles
            };

        case constants.GET_KIDS_ERR: // Cas d'erreur lors de la récupération
            return {
                ...state,
                kids: [],
                status: false,
                error: action.payload, // Message d'erreur
            };

        case constants.UPDATE_CHILD: // Mise à jour d'un enfant
            return {
                ...state,
                kids: state.kids.map((child) =>
                    child.id === action.payload.id ? action.payload : child // Remplace l'enfant modifié
                ),
            };

        case constants.ADD_CHILD: // Ajout d'un nouvel enfant
            return {
                ...state,
                kids: [...state.kids, action.payload], // Ajoute le nouvel enfant à la liste
            };

        case constants.REMOVE_CHILD: // Nouvelle action pour supprimer un enfant
            return {
                ...state,
                kids: state.kids.filter((kid) => kid.id !== action.payload), // Retirer l'enfant avec l'ID correspondant
            };

        case constants.REMOVE_CHILD_AVATAR: // Mettre l'avatar de l'enfant à null
            return {
                ...state,
                kids: state.kids.map((kid) =>
                    kid.id === action.payload.id ? { ...kid, avatar: null } : kid // Met l'avatar à null si l'ID correspond
                ),
            };

        case constants.UPDATE_CHILD_AVATAR: // Mise à jour de l'avatar de l'enfant
            return {
                ...state,
                kids: state.kids.map((kid) =>
                    kid.id === action.payload.id
                        ? { ...kid, avatar: action.payload.avatar } // Met à jour l'avatar avec la nouvelle valeur
                        : kid
                ),
            };

        default:
            return state;
    }
}