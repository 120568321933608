import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import UpdatePassword from './UpdatePassword';
import UpdateEmail from './UpdateEmail';
import UpdateUserName from './UpdateUserName'
import GeneralInfo from './GeneralInfo';
import AddressInfo from './AddressInfo';

export default function ParametersTabs() {
    const { tab } = useParams(); // Récupère le paramètre `tab` depuis l'URL

    // Fonction pour retourner le composant correspondant à la valeur de `tab`
    const renderComponent = () => {
        
        switch (tab) {
            case 'general':
                return <GeneralInfo />; 
            case 'name':
                return <div>Nom (peut être inclus dans GeneralInfo)</div>; // Exemple : Nom
            case 'address':
                return <AddressInfo />; // Composant pour "Adresse"
            case 'UpdateEmail':
                return <UpdateEmail />; // Composant pour "Adresse"
            case 'password':
                return <UpdatePassword />; // Composant pour "Mot de passe"
            case 'nameEdite':
                return <UpdateUserName />; // Exemple : Factures
            default:
                return <div>404 - Page introuvableee</div>; // Affiche un message d'erreur pour les valeurs non valides
        }
    };

    return (
        <Box
          sx={{
            margin: "0 auto",
            width: "60%",
          }}
        >
          {renderComponent()}
        </Box>
      );
            
    
}