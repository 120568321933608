// DateTimeDisplay.jsx
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * DateTimeDisplay
 * Formate une date selon le mode spécifié.
 *
 * @param {Date|string} date - La date à formater (objet Date ou chaîne ISO)
 * @param {string} mode - 'D' pour la date ("DD/MM/YYYY"), 'H' pour l'heure ("HH:mm"), 'DT' pour date et heure ("DD/MM/YYYY HH:mm")
 * @param {boolean} [useUTC=false] - Si true, la date est traitée en UTC.
 * @returns {string|null} La date formatée ou null si aucune date n'est fournie.
 */
export const DateTimeDisplay = (date, mode = "DT", useUTC = false) => {
  if (!date) return null;
  let format;
  switch (mode) {
    case "D":
      format = "DD/MM/YYYY";
      break;
    case "H":
      format = "HH:mm";
      break;
    case "DT":
    default:
      format = "DD/MM/YYYY HH:mm";
      break;
  }
  const d = useUTC ? dayjs.utc(date) : dayjs(date);
  return d.format(format);
};

/**
 * extractTimeUTC
 * Extrait l'heure et les minutes d'une date en UTC en se basant sur la date d'aujourd'hui.
 *
 * @param {Date|string} dateString - La date complète (objet Date ou chaîne ISO)
 * @returns {dayjs.Dayjs|null} Un objet Dayjs contenant uniquement l'heure et les minutes, ou null si dateString est invalide.
 */
export const extractTimeUTC = (dateString) => {
  if (!dateString) return null;
  const fullDateUTC = dayjs.utc(dateString);
  // Crée un nouvel objet Dayjs basé sur la date d'aujourd'hui en UTC, et lui applique l'heure et les minutes extraites
  return dayjs().utc().hour(fullDateUTC.hour()).minute(fullDateUTC.minute()).second(0);
};

export default DateTimeDisplay;