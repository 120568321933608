import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import api from "../../../utils/api";

export default function DeleteProfileModal({
  childName,
  childId,
  open,
  onClose,
  onDelete,
}) {
  const handleDelete = async () => {
    try {
      // Requête API pour supprimer l'enfant
      await api.delete("parent/deleteChild", { data: { child_id: childId } });

      // Appeler la fonction onDelete pour mettre à jour Redux
      onDelete(childId);
      onClose(); // Fermer le modal
    } catch (error) {
      console.error(
        "Erreur lors de la suppression :",
        error.response?.data || error.message
      );
      alert("Une erreur est survenue lors de la suppression de l'enfant.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Supprimer le profil</DialogTitle>
      <DialogContent>
        <Typography>
          Êtes-vous sûr de vouloir supprimer le profil de {childName} ? Toutes
          les données associées seront perdues.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Annuler
        </Button>
        <Button onClick={handleDelete} color="error">
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
