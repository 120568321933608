// src/components/ListeOrganizers.jsx
import React, { useEffect } from 'react';
import { ListItemText, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { constants } from '../../../auth/_constants/constants';
import { getOrganizers } from '../../../reduxAction/organizers.action';
import { getActivities } from '../../../reduxAction/activities.action';
import { useTheme } from '@mui/material/styles';

export default function ListeOrganizers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { section, tab } = useParams();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  // On s'assure que organizers est toujours un tableau
  const organizers = useSelector((state) => state.organizers.organizers);

  useEffect(() => {
    if (organizers.length === 0) {
      dispatch(getOrganizers());
    }
  }, [dispatch, organizers.length, section]);

  useEffect(() => {
    if (tab && organizers.length > 0) {
      const currentOrganizer = organizers.find(
        (organizer) => organizer.shortCodeOrganizer === tab
      );
      if (currentOrganizer) {
        dispatch({
          type: constants.GET_ORGANIZER,
          payload: currentOrganizer,
        });
        dispatch(getActivities(currentOrganizer?.id));
      }
    }
  }, [dispatch, organizers, tab]);

  const handleClick = (shortCode) => {
    const clickedOrganizer = organizers.find(
      (organizer) => organizer.shortCodeOrganizer === shortCode
    );
    if (clickedOrganizer) {
      dispatch({
        type: constants.GET_ORGANIZER,
        payload: clickedOrganizer,
      });
    }
    navigate(`/organizer/o/${shortCode}/info-organizer`);
  };

  return (
    <>
      {organizers.map((organizer) => (
        <MenuItem
          key={organizer.id}
          onClick={() => handleClick(organizer.shortCodeOrganizer)}
          sx={{
            backgroundColor:
              tab === organizer.shortCodeOrganizer ? 'orange' : 'transparent',
            color:
              tab === organizer.shortCodeOrganizer
                ? 'white'
                : isDark
                ? theme.palette.common.white
                : 'inherit',
            "&:hover": {
              backgroundColor:
                tab === organizer.shortCodeOrganizer
                  ? 'darkorange'
                  : isDark
                  ? theme.palette.grey[600]
                  : 'lightgray',
              color: isDark ? theme.palette.common.white : 'inherit',
            },
          }}
        >
          <ListItemText primary={organizer.organizationName} />
        </MenuItem>
      ))}
    </>
  );
}