import { constants } from '../auth/_constants/constants';
import api from '../utils/api';

export const getKids = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('parent/children'); // Appel à l'API

            if (response.data.status) {
                dispatch({
                    type: constants.GET_KIDS, // L'action doit correspondre à celle gérée par le reducer
                    payload: response.data, // Les enfants seront dans `response.data.children`
                });
            } else {
                dispatch({
                    type: constants.GET_KIDS_ERR,
                    payload: response.data.message, // Gérer le message d'erreur
                });
            }
        } catch (err) {
            dispatch({
                type: constants.GET_KIDS_ERR,
                payload: err.message, // Gérer les erreurs inattendues
            });
        }
    };
};