// src/components/GeneralInfo.jsx
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Avatar,
  Typography,
  Divider,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
  Alert,
} from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4"; // Icône lune
import Brightness7Icon from "@mui/icons-material/Brightness7"; // Icône soleil
import AvatarEditorModal from "./AvatarEditorModal";
import EditIcon from "@mui/icons-material/Edit";
import { getUserAdress } from "../reduxAction/user.action";
import { useDispatch, useSelector } from "react-redux";
import { ThemeModeContext } from "../index"; // Vérifiez que le chemin est correct

export default function GeneralInfo() {
  // Récupérer les informations de l'utilisateur depuis le store Redux
  const user = useSelector((state) => state.user?.user);
  const address = useSelector((state) => state.user?.adress);
  const dispatch = useDispatch();
  const [openAvatarEditorModal, setOpenAvatarEditorModal] = useState(false);

  // Utiliser le contexte pour le thème
  const { mode, toggleTheme } = useContext(ThemeModeContext);

  useEffect(() => {
    if (!address || address === "") {
      dispatch(getUserAdress());
    }
  }, [address, dispatch]);

  if (!user) {
    return (
      <Box sx={{ padding: 3 }}>
        <Alert severity="error">
          Aucun utilisateur connecté. Veuillez vous connecter pour afficher vos informations.
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* Bouton Switch de basculement de thème */}
      <Box
        sx={{
          m: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography sx={{ mr: 1 }}>Thème</Typography>
        <Brightness7Icon sx={{ mr: 1 }} />
        <FormControlLabel
          control={
            <Switch
              checked={mode === "dark"}
              onChange={toggleTheme}
              color="default"
            />
          }
          label=""
        />
        <Brightness4Icon sx={{ ml: 1 }} />
      </Box>

      {/* Titre principal et informations */}
      <Card sx={{ mb: 4, boxShadow: 1 }}>
        <CardContent>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                mr: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              {/* Avatar cliquable */}
              <Avatar
                alt={`${user?.firstname} ${user?.lastname}`}
                src={
                  user?.avatar
                    ? `https://www.i-kiddy.com/images/avatar/${user?.avatar}`
                    : "https://via.placeholder.com/150"
                }
                sx={{
                  width: 100,
                  height: 100,
                  mb: 1,
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => setOpenAvatarEditorModal(true)}
              />

              {/* Cercle avec le crayon */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: -5,
                  right: "calc(50% - 12px)",
                  width: 24,
                  height: 24,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 6px rgba(0,0,0,0.2)",
                  cursor: "pointer",
                }}
                onClick={() => setOpenAvatarEditorModal(true)}
              >
                <EditIcon fontSize="small" sx={{ color: "#1976d2" }} />
              </Box>
            </Box>

            {/* Informations utilisateur */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "left",
              }}
            >
              <Typography variant="h6">
                {user?.firstname} {user?.lastname}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {user?.email}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {address?.phoneNumber || "Téléphone non renseigné"}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />

          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Date de naissance :</strong>{" "}
            {user?.birthday
              ? new Date(user?.birthday).toLocaleDateString()
              : "Non renseigné"}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Genre :</strong> {user?.gender || "Non renseigné"}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Adresse :</strong>{" "}
            {address
              ? `${address.streetNumber}, ${address.streetName}, ${address.city}, ${address.postalCode}, ${address.country}`
              : "Non renseignée"}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Créé le :</strong>{" "}
            {user?.created_at
              ? new Date(user?.created_at).toLocaleDateString()
              : "Non renseigné"}
          </Typography>
          <Typography variant="body1">
            <strong>Dernière modification :</strong>{" "}
            {user?.updated_at
              ? new Date(user?.updated_at).toLocaleDateString()
              : "Non renseigné"}
          </Typography>
        </CardContent>
      </Card>

      <AvatarEditorModal
        typePathBD="user"
        currentAvatar={user?.avatar}
        open={openAvatarEditorModal}
        onClose={() => setOpenAvatarEditorModal(false)}
      />
    </Box>
  );
}