import { constants } from '../auth/_constants/constants';
import api from '../utils/api';

export const getOrganizers = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('organizer/organizers'); // Appel à l'API
            if (response.data.status) {
                dispatch({
                    type: constants.GET_ORGANIZERS, // L'action doit correspondre à celle gérée par le reducer
                    payload: response.data.organizers, // Ici payload est directement l'array des organisateurs
                });
            } else {
                dispatch({
                    type: constants.GET_ORGANIZERS_ERR,
                    payload: response.data.message,
                });
            }
        } catch (err) {
            dispatch({
                type: constants.GET_ORGANIZERS_ERR,
                payload: err.message,
            });
        }
    };
};