import axios from 'axios';
import Cookies from 'js-cookie'; 
import { constants } from '../auth/_constants/constants';
import { Hoster } from '../auth/Hoster';
import { store } from '../index'; // Importer le store
import i18n from '../lang/i18n'; // Importer la configuration i18n

// Création d'une instance Axios
const api = axios.create({
    baseURL: Hoster.path,
});

// Variables pour gérer les tokens
let isRefreshing = false; // Drapeau pour savoir si le refresh est en cours
let failedQueue = []; // File d'attente des requêtes en échec

// Récupérer le token CSRF depuis la balise <meta>
const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

// Ajouter automatiquement le token CSRF à toutes les requêtes si disponible
if (csrfToken) {
    api.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
}

// Fonction pour gérer la file d'attente des requêtes pendant le refresh du token
const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });
    failedQueue = [];
};

// Intercepteur de requête pour ajouter les en-têtes nécessaires (access_token, CSRF, et langue)
api.interceptors.request.use(
    (config) => {
        // Ajouter l'Authorization header si un access_token est disponible
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        // Ajouter la langue choisie comme en-tête personnalisé `Accept-Language`
        const currentLanguage = i18n.language; // Obtenir la langue actuellement choisie dans i18n
        config.headers['Accept-Language'] = currentLanguage; // Ajouter la langue aux en-têtes

        return config;
    },
    (error) => Promise.reject(error)
);

// Fonction de gestion des tokens expirés
const handleTokenRefresh = async () => {
    const refreshToken = Cookies.get('refresh_token');
    if (!refreshToken) {
        throw new Error("No refresh token found");
    }
    const response = await axios.post(`${Hoster.path}refresh`, { 'refresh_token': refreshToken });
    const newAccessToken = response.data.access_token;
    const newRefreshToken = response.data.refresh_token; // Obtenir le nouveau refresh_token
    
    // Stocker le nouveau access_token et refresh_token
    localStorage.setItem('access_token', newAccessToken);
    Cookies.set('refresh_token', newRefreshToken, {
        expires: 15,
        secure: true,
        sameSite: 'Strict',
    });
    
    return newAccessToken; // Retourne le nouveau access_token
};

// Fonction de déconnexion et redirection vers la page de login
const handleLogout = (dispatch) => {
    dispatch({ type: constants.LOGOUT });
    localStorage.removeItem('access_token');
    Cookies.remove('refresh_token');
    window.location.href = '/login';
};

// Intercepteur de réponse pour gérer les erreurs
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Vérifier si la requête a échoué avec un 401 Unauthorized et si on n'a pas encore essayé de rafraîchir
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                // Si un rafraîchissement est déjà en cours, ajouter la requête à la file d'attente
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                .then((token) => {
                    // Mettre à jour l'en-tête avec le nouveau token
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    return api(originalRequest);
                })
                .catch((err) => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // Récupérer dispatch depuis le store Redux
            const dispatch = store.dispatch;

            return new Promise((resolve, reject) => {
                handleTokenRefresh()
                    .then((newAccessToken) => {
                        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                        processQueue(null, newAccessToken); // Résoudre toutes les requêtes en attente
                        resolve(api(originalRequest));
                    })
                    .catch((err) => {
                        processQueue(err, null); // Rejeter toutes les requêtes en attente
                        handleLogout(dispatch); // Déconnexion si le refresh échoue
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false; // Remettre le drapeau à false
                    });
            });
        }

        // Gérer les erreurs CSRF (419)
        if (error.response && error.response.status === 419) {
            // Informer l'utilisateur d'une session expirée
            alert('Votre session a expiré. Veuillez actualiser la page ou vous reconnecter.');
            const dispatch = store.dispatch;
            handleLogout(dispatch); // Déconnexion sécurisée
        }

        return Promise.reject(error);
    }
);

export default api;