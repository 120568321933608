import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import ChildDetailsModal from "./ChildDetailsModal";
import DeleteProfileModal from "./DeleteProfileModal";
import { getKids } from "../../../reduxAction/kids.action";
import EditIcon from "@mui/icons-material/Edit";
import AvatarEditorModal from "../../../components/AvatarEditorModal";
import ChildMedicalModal from "./ChildMedicalModal";
import ChildPrederencesModal from "./ChildPrederencesModal";

export default function ChildrenList({ onEdit }) {
  const dispatch = useDispatch();
  const children = useSelector((state) => state.kids.kids);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openMedicalModal, setOpenMedicalModal] = useState(false);
  const [openPreferencesModal, setOpenPreferencesModal] = useState(false);
  const [openAvatarEditorModal, setOpenAvatarEditorModal] = useState(false);
  const [selectedChildAvatar, setSelectedChildAvatar] = useState(null);
  const [openDeleteProfileModal, setOpenDeleteProfileModal] = useState(false);

  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    if (!children || children.length === 0) {
      dispatch(getKids());
    }
  }, [dispatch, children]);

  const handleMenuOpen = (event, child) => {
    setSelectedChild(child);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "flex-start",
          mt: 1,
        }}
      >
        {children.map((child) => (
          <Card
            key={child.id}
            sx={{
              width: 230,
              border: "1px solid #e0e0e0",
              borderRadius: 2,
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: 250,
            }}
          >
            {/* Avatar */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedChildAvatar(child.avatar);
                  setSelectedChild(child);
                  setOpenAvatarEditorModal(true);
                }}
              >
                <Avatar
                  src={
                    child.avatar
                      ? `https://www.i-kiddy.com/images/avatar/${child.avatar}`
                      : ""
                  }
                  alt={child.firstname}
                  sx={{ width: 65, height: 65 }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: -9,
                    right: "calc(50% - 12px)",
                    width: 24,
                    height: 24,
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 6px rgba(0,0,0,0.2)",
                  }}
                  onClick={() => {
                    setSelectedChildAvatar(child.avatar);
                    setOpenAvatarEditorModal(true);
                  }}
                >
                  <EditIcon fontSize="small" sx={{ color: "#1976d2" }} />
                </Box>
              </Box>

              {/* Icône de menu déroulant */}
              <IconButton
                onClick={(event) => handleMenuOpen(event, child)}
                sx={{ position: "absolute", top: -10, right: 0 }}
              >
                <MoreHorizIcon />
              </IconButton>
            </Box>

            {/* Nom de l'enfant */}
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "center",
                mb: 1,
              }}
            >
              {child.firstname} {child.lastname}
            </Typography>

            <CardContent sx={{ padding: 0, flex: "1 0 auto" }}>
              {[
                {
                  label: "Date de naissance",
                  value: new Date(child.birthday).toLocaleDateString(),
                },
                { label: "Genre", value: child.gender },
                { label: "ID national", value: child.national_id || "N/A" },
                {
                  label: "Créé le",
                  value: new Date(child.created_at).toLocaleDateString(),
                },
                {
                  label: "Modifié le",
                  value: new Date(child.updated_at).toLocaleDateString(),
                },
              ].map((info, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 0.5,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: 12, fontWeight: "bold", textAlign: "left" }}
                  >
                    {info.label} :
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: 12, textAlign: "right" }}
                  >
                    {info.value}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Menu déroulant */}
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenDetailsModal(true);
            handleMenuClose();
          }}
        >
          Détails
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenMedicalModal(true);
            handleMenuClose();
          }}
        >
          Fiche médicale
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenPreferencesModal(true);
            handleMenuClose();
          }}
        >
          Préférences
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEdit(selectedChild);
            handleMenuClose();
          }}
        >
          Modifier
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteProfileModal(true);
            handleMenuClose();
          }}
        >
          Supprimer profil
        </MenuItem>
      </Menu>

      {/* Modals */}
      <ChildDetailsModal
        name={selectedChild?.firstname + " " + selectedChild?.lastname}
        childId={selectedChild?.id}
        open={openDetailsModal}
        onClose={() => setOpenDetailsModal(false)}
      />
      <ChildMedicalModal
        name={selectedChild?.firstname + " " + selectedChild?.lastname}
        childId={selectedChild?.id}
        open={openMedicalModal}
        onClose={() => setOpenMedicalModal(false)}
      />
      <ChildPrederencesModal
        name={selectedChild?.firstname + " " + selectedChild?.lastname}
        childId={selectedChild?.id}
        open={openPreferencesModal}
        onClose={() => setOpenPreferencesModal(false)}
      />
      <AvatarEditorModal
        name={selectedChild?.firstname + " " + selectedChild?.lastname}
        typePathBD="child"
        childId={selectedChild?.id}
        open={openAvatarEditorModal}
        currentAvatar={selectedChildAvatar || null}
        onClose={() => {
          setOpenAvatarEditorModal(false);
          setSelectedChildAvatar(null);
        }}
      />
      <DeleteProfileModal
        childName={selectedChild?.firstname + " " + selectedChild?.lastname}
        childId={selectedChild?.id}
        open={openDeleteProfileModal}
        onClose={() => setOpenDeleteProfileModal(false)}
      />
    </>
  );
}
