// src/components/TabEditeActivity.jsx
import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import BasicInfoTab from './BasicInfoTab';
import ActivityPeriodForm from './ActivityPeriodForm';
import ParticipationOptionsForm from './ParticipationOptionsForm';
import DaycareOptionsForm from './DaycareOptionsForm';
import OptionCreateActivity from './OptionCreateActivity';
import OptionDisplayOn from './OptionDisplayOn';

// Composant TabPanel pour gérer l'affichage du contenu de chaque onglet
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-edite-activity-panel-${index}`}
      aria-labelledby={`tab-edite-activity-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* On retire le <Typography> et on rend directement children */}
          {children}
        </Box>
      )}
    </div>
  );
}
  
  // Fonction d'accessibilité pour les onglets
  function a11yProps(index) {
    return {
      id: `tab-edite-activity-${index}`,
      'aria-controls': `tab-edite-activity-panel-${index}`,
    };
  }
  
  export default function TabEditeActivity() {
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ width: '100%' }}>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="Onglets d'édition d'activité"
          variant="fullWidth"
        >
          <Tab label="Base" {...a11yProps(0)} sx={{ textTransform: 'capitalize' }}/>
          <Tab label="Dates" {...a11yProps(1)} sx={{ textTransform: 'capitalize' }}/>
          <Tab label="Tarifs" {...a11yProps(2)} sx={{ textTransform: 'capitalize' }}/>
          <Tab label="Participations" {...a11yProps(3)} sx={{ textTransform: 'capitalize' }}/>
          <Tab label="Services" {...a11yProps(4)} sx={{ textTransform: 'capitalize' }}/>
          <Tab label="Conditions" {...a11yProps(5)} sx={{ textTransform: 'capitalize' }}/>


        </Tabs>
        <TabPanel value={value} index={0}>
          <BasicInfoTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="subtitle1" gutterBottom>
            <ActivityPeriodForm/>
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ParticipationOptionsForm/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DaycareOptionsForm/>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <OptionCreateActivity/>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <OptionDisplayOn/>
        </TabPanel>
      </Box>
    );
  }