import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Switch,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import api from "../../../utils/api";

export default function ChildPreferencesModal({ name, childId, open, onClose }) {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [saving, setSaving] = useState({});
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  /**
   * 🔥 Charge les questions et réponses enregistrées
   */
  const fetchPreferencesData = useCallback(async () => {
    try {
      const [questionsResponse, answersResponse] = await Promise.all([
        api.get("parent/preferences-questions"),
        api.get(`parent/preference-answers/${childId}`),
      ]);

      const fetchedAnswers = {};
      questionsResponse.data.forEach((q) => {
        fetchedAnswers[q.id] = { value: Boolean(answersResponse.data[q.id]) };
      });

      setQuestions(questionsResponse.data);
      setAnswers(fetchedAnswers);

   
    } catch (error) {
      setError("Erreur lors du chargement des questions et réponses.");
    }
  }, [childId]);

  /**
   * 🔥 Charge les données dès l'ouverture du modal
   */
  useEffect(() => {
    if (childId && open) {
      fetchPreferencesData();
    }
  }, [childId, open, fetchPreferencesData]);

  /**
   * 🔥 Met à jour une seule réponse à la fois avec loading individuel
   */
  const handleChange = async (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: { ...prev[questionId], value },
    }));

    setSaving((prev) => ({ ...prev, [questionId]: true }));

    const data = {
      child_id: childId,
      question_id: questionId,
      answer: value,
    };

    try {
      await api.post("parent/preference-answers", data);

      setSnackbarMessage("Préférence mise à jour !");
      setSnackbarOpen(true);

    } catch (error) {
      setSnackbarMessage("Erreur lors de l'enregistrement.");
      setSnackbarOpen(true);

      setAnswers((prev) => ({
        ...prev,
        [questionId]: { ...prev[questionId], value: !value },
      }));
    } finally {
      setSaving((prev) => ({ ...prev, [questionId]: false }));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Préférences de {name}</DialogTitle>
      <DialogContent dividers>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          * Les préférences de l'enfant aideront à recommander des activités adaptées.
        </Typography>

        {questions.map((q, index) => (
  <Box
    key={q.id && q.id !== "0" ? `question-${q.id}` : `question-index-${index}`}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #ddd",
      pb: 1,
      transition: "background-color 0.3s ease",
      minHeight: "40px",
    }}
  >
    <Typography variant="body1">{q.question_text}</Typography>

    <Box sx={{ display: "flex", alignItems: "center", minHeight: "40px" }}>
      {saving[q.id] ? (
        <CircularProgress size={20} />
      ) : (
        <Switch
          checked={Boolean(answers[q.id]?.value)}
          onChange={(e) => handleChange(q.id, e.target.checked)}
          color="primary"
          disabled={saving[q.id]}
        />
      )}
      <Typography sx={{ ml: 1 }}>
        {answers[q.id]?.value ? "Oui" : "Non"}
      </Typography>
    </Box>
  </Box>
))}
      </DialogContent>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Dialog>
  );
}