import React, { useState } from 'react';
import { 
  Box,
  Button,
  TextField,
  Alert,
  Grid,
  Typography
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DaycareOptionsForm = ({ onSubmit }) => {
  // États pour la garderie du matin
  const [morningStartTime, setMorningStartTime] = useState(null);
  const [morningPrice, setMorningPrice] = useState('');
  
  // États pour la garderie du soir
  const [eveningEndTime, setEveningEndTime] = useState(null);
  const [eveningPrice, setEveningPrice] = useState('');
  
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Vérifier qu'au moins une option est renseignée
    if (!morningStartTime && !eveningEndTime) {
      setError('Veuillez renseigner au moins une option de garderie.');
      return;
    }
    // Pour chaque option renseignée, le prix doit être fourni
    if (morningStartTime && morningPrice === '') {
      setError('Veuillez renseigner le prix pour la garderie du matin.');
      return;
    }
    if (eveningEndTime && eveningPrice === '') {
      setError('Veuillez renseigner le prix pour la garderie du soir.');
      return;
    }
    
    const formData = {
      morningDaycare: morningStartTime ? {
        startTime: morningStartTime, // Heure de début de la garderie du matin
        price: Number(morningPrice)
      } : null,
      eveningDaycare: eveningEndTime ? {
        endTime: eveningEndTime, // Heure de fin de la garderie du soir
        price: Number(eveningPrice)
      } : null,
    };

    if (onSubmit) {
      onSubmit(formData);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ 
          maxWidth: 600, 
          mx: 'auto', 
          p: 2, 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 2 
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        
        <Typography variant="h6" component="h2">Options de garderie</Typography>
        <Typography variant="body2" color="text.secondary">
          Pour la garderie du <strong>matin</strong>, renseignez l'heure de début. L'heure de fin sera fixée par l'heure de début de l'activité.
          Pour la garderie du <strong>soir</strong>, renseignez l'heure de fin. L'heure de début sera fixée par l'heure de fin de l'activité.
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Le sélecteur d'heure affiche une horloge pour faciliter le choix.
        </Typography>
        
        <Grid container spacing={2}>
          {/* Garderie du matin */}
          <Grid item xs={12} sm={6}>
            <TimePicker
              label="Heure de début (matin)"
              value={morningStartTime}
              onChange={(newValue) => setMorningStartTime(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small'
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Prix (matin)"
              type="number"
              value={morningPrice}
              onChange={(e) => setMorningPrice(e.target.value)}
              fullWidth
              size="small"
              InputProps={{
                inputProps: { style: { textAlign: 'right' } }
              }}
            />
          </Grid>

          {/* Garderie du soir */}
          <Grid item xs={12} sm={6}>
            <TimePicker
              label="Heure de fin (soir)"
              value={eveningEndTime}
              onChange={(newValue) => setEveningEndTime(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small'
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Prix (soir)"
              type="number"
              value={eveningPrice}
              onChange={(e) => setEveningPrice(e.target.value)}
              fullWidth
              size="small"
              InputProps={{
                inputProps: { style: { textAlign: 'right' } }
              }}
            />
          </Grid>
        </Grid>
        
        <Button type="submit" variant="contained" fullWidth>
          Enregistrer les options de garderie
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default DaycareOptionsForm;