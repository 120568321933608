import React, { useState } from 'react';
import { 
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Alert,
  Typography
} from '@mui/material';

const OptionCreateActivity = ({ onSubmit }) => {
  // États pour les options
  const [participationCondition, setParticipationCondition] = useState('');
  const [validationMethod, setValidationMethod] = useState('auto'); // 'auto' ou 'manual'
  const [mealOption, setMealOption] = useState('repas_chaud'); // 'repas_chaud' ou 'tartine'
  const [enableWaitingList, setEnableWaitingList] = useState(false);
  const [posterFile, setPosterFile] = useState(null);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPosterFile(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Validation de base : participation condition, validationMethod et mealOption ont déjà des valeurs par défaut.
    // On peut ajouter d'autres validations selon les besoins.
    if (enableWaitingList && !participationCondition) {
      setError('Veuillez préciser les conditions de participation pour activer la liste d\'attente.');
      return;
    }

    const formData = {
      participationCondition,
      validationMethod, // 'auto' pour validation automatique, 'manual' pour validation par l'organisateur
      mealOption,       // 'repas_chaud' ou 'tartine'
      enableWaitingList,
      posterFile,       // Objet File (à traiter lors de l'upload)
    };

    if (onSubmit) {
      onSubmit(formData);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2, maxWidth: 600, mx: 'auto', display: 'flex', flexDirection: 'column', gap: 3 }}>
      {error && <Alert severity="error">{error}</Alert>}

      <Typography variant="h6" component="h2">Options de participation</Typography>

      {/* Conditions de participation */}
      <TextField
        label="Conditions de participation"
        value={participationCondition}
        onChange={(e) => setParticipationCondition(e.target.value)}
        placeholder="Ex: Être habitant de la commune X"
        fullWidth
        size="small"
      />

      {/* Validation de l'inscription */}
      <FormControl component="fieldset">
        <FormLabel component="legend">Validation de l'inscription</FormLabel>
        <RadioGroup
          row
          name="validationMethod"
          value={validationMethod}
          onChange={(e) => setValidationMethod(e.target.value)}
        >
          <FormControlLabel value="auto" control={<Radio />} label="Validation automatique" />
          <FormControlLabel value="manual" control={<Radio />} label="Validation par l'organisateur" />
        </RadioGroup>
      </FormControl>

      {/* Option repas */}
      <FormControl component="fieldset">
        <FormLabel component="legend">Option repas</FormLabel>
        <RadioGroup
          row
          name="mealOption"
          value={mealOption}
          onChange={(e) => setMealOption(e.target.value)}
        >
          <FormControlLabel value="repas_chaud" control={<Radio />} label="Repas chaud" />
          <FormControlLabel value="tartine" control={<Radio />} label="Apporter ses tartines" />
        </RadioGroup>
      </FormControl>

      {/* Liste d'attente */}
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWaitingList}
            onChange={(e) => setEnableWaitingList(e.target.checked)}
          />
        }
        label="Activer la liste d'attente si l'activité est complète"
      />

      {/* Illustration (poster) */}
      <Box>
        <FormLabel component="legend">Poster d'illustration</FormLabel>
        <input 
          type="file" 
          accept="image/*" 
          onChange={handleFileChange} 
          style={{ marginTop: '8px' }}
        />
        {posterFile && (
          <Typography variant="caption" color="text.secondary">
            Fichier sélectionné: {posterFile.name}
          </Typography>
        )}
      </Box>

      <Button type="submit" variant="contained" fullWidth>
        Enregistrer les options
      </Button>
    </Box>
  );
};

export default OptionCreateActivity;