import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../utils/api';
// import Footer from '../components/Footer';
// import NavWrapper from '../components/NavWrapper';

const VerifyEmail = () => {
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState('loading'); // loading, success, error, expired
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const hasRun = useRef(false);

    useEffect(() => {
        const token = searchParams.get('token');

        if (hasRun.current) return; // ⛔ ne pas re-exécuter
        hasRun.current = true; // ✅ marquer comme exécuté

        if (!token) {
            setStatus('error');
            setMessage(t('verifyEmail.tokenMissing'));
            return;
        }

        const verifyEmail = async () => {
            try {
                const response = await api.get(`auth/verify-email?token=${token}`);

                if (response.data.success) {
                    setStatus('success');
                    setMessage(t('verifyEmail.successMessage'));
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } else {
                    // ⚠️ ne pas écraser un succès précédent
                    if (status !== 'success') {
                        setStatus('error');
                        setMessage(response.data.message || t('verifyEmail.errorMessage'));
                    }
                }
            } catch (error) {
                if (status === 'success') return; // ⛔ ne rien faire si déjà validé

                const errorMessage = error?.response?.data?.message || t('verifyEmail.errorMessage');
                setMessage(errorMessage);

                if (errorMessage.toLowerCase().includes('expired')) {
                    setStatus('expired');
                } else {
                    setStatus('error');
                }
            }
        };

        verifyEmail();
    }, [searchParams, navigate, t, status]);

    return (
        <div style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
        }}>
            {/* <NavWrapper /> */}

            <div style={{
                flexGrow: 1,
                marginTop: '60px',
                textAlign: 'center',
                padding: '20px'
            }}>
                {status === 'loading' && <p>{t('verifyEmail.loading')}</p>}

                {status === 'success' && (
                    <p style={{ color: 'green' }}>{message}</p>
                )}

                {status === 'error' && (
                    <p style={{ color: 'red' }}>{message}</p>
                )}

                {status === 'expired' && (
                    <div>
                        <p style={{ color: 'orange' }}>{message}</p>
                        <button
                            onClick={() => navigate('/resend-verification')}
                            style={{
                                backgroundColor: '#ff2d20',
                                color: '#fff',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            {t('verifyEmail.resendButton')}
                        </button>
                    </div>
                )}
            </div>

            {/* <Footer /> */}
        </div>
    );
};

export default VerifyEmail;