// src/components/MenuSideOrganizer.jsx
import React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import ListeOrganizers from '../components/ListeOrganizers';
import { useTheme } from '@mui/material/styles';

export default function MenuSideOrganizer() {
  const navigate = useNavigate();
  const { section } = useParams();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const directionPath = (path) => {
    navigate('/organizer/' + path);
  };

  return (
    <Box>
      <MenuList sx={{ flexGrow: 1 }}>
        {/* Accueil */}
        <MenuItem
          onClick={() => directionPath('new-organization')}
          sx={{
            backgroundColor:
              section === 'new-organization' ? 'orange' : 'transparent',
            color:
              section === 'new-organization'
                ? 'white'
                : isDark
                ? theme.palette.common.white
                : 'inherit',
            '&:hover': {
              backgroundColor:
                section === 'new-organization'
                  ? 'darkorange'
                  : isDark
                  ? theme.palette.grey[600]
                  : 'lightgray',
              color: isDark ? theme.palette.common.white : 'inherit',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <HomeIcon
                fontSize="small"
                sx={{
                  color:
                    section === 'new-organization'
                      ? 'white'
                      : isDark
                      ? theme.palette.common.white
                      : 'inherit',
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Nouvelle organisation" />
          </Box>
        </MenuItem>
        <ListeOrganizers />
      </MenuList>
    </Box>
  );
}