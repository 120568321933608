// src/components/OrganizationCard.jsx
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Grid,
  Paper,
  IconButton,
  Dialog,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, PieChart, Pie, Cell, Legend } from 'recharts';
import CreateOrganizerForm from '../../page/CreatePortalForm';

// Styled components qui réagissent au thème
const StyledCard = styled(Card)(({ theme }) => ({
  width: '70%',
  margin: '20px auto',
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.paper,
}));

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: theme.palette.mode === 'dark' 
    ? theme.palette.grey[800] 
    : theme.palette.grey[100],
}));

// Couleurs pour le PieChart
const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

const OrganizationCard = () => {
  const theme = useTheme();
  const organizer = useSelector((state) => state.organizers.organizer);
  const [openModal, setOpenModal] = useState(false);

  if (!organizer) {
    return (
      <Typography variant="body1" color="text.secondary">
        Aucune organisation disponible
      </Typography>
    );
  }

  // Extraction des propriétés de l'organisateur
  const {
    organizationName,
    streetName,
    streetNumber,
    postalCode,
    city,
    country,
    phoneNumber,
    email,
    website,
    description,
    category,
    bankAccount,
    shortCodeOrganizer,
    status,
    taxId,
    companyNumber,
    activitiesInProgress = 0,
    activitiesCreated = 0,
    participantsCount = 0,
    latitude,
    longitude,
  } = organizer;

  // Données pour les graphiques
  const barChartData = [
    { name: 'En cours', value: activitiesInProgress },
    { name: 'Créées', value: activitiesCreated },
    { name: 'Participants', value: participantsCount },
  ];

  const pieChartData = [
    { name: 'En cours', value: activitiesInProgress },
    { name: 'Créées', value: activitiesCreated },
    { name: 'Participants', value: participantsCount },
  ];

  const handleEditClick = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  // Préparation des données à transmettre au formulaire d'édition
  const existingData = {
    id: organizer.id,
    organizationName,
    bankAccount,
    taxId,
    companyNumber,
    email,
    phoneNumber,
    website,
    category,
    description,
    address: {
      streetName,
      streetNumber,
      postalCode,
      city,
      country,
      latitude: latitude || null,
      longitude: longitude || null,
    },
  };

  return (
    <>
      {/* Modal d'édition */}
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            maxHeight: 'calc(100vh - 32px)',
            overflowY: 'auto',
          },
        }}
      >
        <CreateOrganizerForm
          title="Modifier"
          existingData={existingData}
          onClose={handleModalClose}
        />
      </Dialog>

      <StyledCard>
        <CardContent sx={{ p: 3 }}>
          {/* En-tête avec titre et icône d'édition */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Box>
              <Typography variant="h4" gutterBottom color={theme.palette.text.primary}>
                {organizationName}
              </Typography>
              {category && (
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </Typography>
              )}
            </Box>
            <IconButton
              aria-label="Modifier"
              sx={{ color: theme.palette.text.secondary }}
              onClick={handleEditClick}
            >
              <EditIcon />
            </IconButton>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Informations d'adresse et de contact */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" color={theme.palette.text.primary}>
              <strong>Adresse :</strong> {streetName} {streetNumber}, {postalCode} {city}, {country}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" color={theme.palette.text.primary}>
              <strong>Contact :</strong> Téléphone : {phoneNumber} | Email : {email}
            </Typography>
            {website && (
              <Typography variant="body1" color={theme.palette.text.primary}>
                <strong>Site web :</strong> {website}
              </Typography>
            )}
          </Box>
          {bankAccount && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" color={theme.palette.text.primary}>
                <strong>Compte bancaire :</strong> {bankAccount}
              </Typography>
            </Box>
          )}
          {description && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" color={theme.palette.text.primary}>
                {description}
              </Typography>
            </Box>
          )}

          <Divider sx={{ my: 2 }} />

          <Typography variant="caption" color={theme.palette.text.secondary}>
            Code : {shortCodeOrganizer} - Statut : {status}
          </Typography>

          {/* Mini dashboard avec statistiques */}
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <StatCard>
                  <Typography variant="h6" color={theme.palette.text.primary}>
                    {activitiesInProgress}
                  </Typography>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    Activités en cours
                  </Typography>
                </StatCard>
              </Grid>
              <Grid item xs={4}>
                <StatCard>
                  <Typography variant="h6" color={theme.palette.text.primary}>
                    {activitiesCreated}
                  </Typography>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    Activités créées
                  </Typography>
                </StatCard>
              </Grid>
              <Grid item xs={4}>
                <StatCard>
                  <Typography variant="h6" color={theme.palette.text.primary}>
                    {participantsCount}
                  </Typography>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    Participants
                  </Typography>
                </StatCard>
              </Grid>
            </Grid>
          </Box>

          {/* Section des graphiques */}
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" color={theme.palette.text.primary} gutterBottom>
                  Statistiques - Bar Chart
                </Typography>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={barChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                    <XAxis dataKey="name" stroke={theme.palette.text.secondary} />
                    <YAxis stroke={theme.palette.text.secondary} />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" color={theme.palette.text.primary} gutterBottom>
                  Statistiques - Pie Chart
                </Typography>
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Pie data={pieChartData} dataKey="value" nameKey="name" outerRadius={80} fill="#8884d8" label>
                      {pieChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </StyledCard>
    </>
  );
};

export default OrganizationCard;