// src/components/MenuSide.jsx
import React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, useParams } from "react-router-dom";
import Logout from "../../../functions/Logout";
import MenuSideOrganizer from "./MenuSideOrganizer";
import { useTheme } from "@mui/material/styles";

export default function MenuSide() {
  const navigate = useNavigate();
  const { section } = useParams(); // Récupère la section depuis l'URL
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  // Exemple de données pour les badges
  const notifications = {
    messages: 7,
    organizers: 10,
    organizerMarked: 3,
    activitiesMarked: 8,
    invoices: 4,
  };

  const directionPath = (path) => {
    navigate("/organizer/" + path);
  };

  const { handleLogout } = Logout(); // Récupérer la fonction handleLogout

  // Style commun pour les liens sélectionnés
  const selectedStyles = {
    backgroundColor: "orange",
    color: "white",
    "&:hover": {
      backgroundColor: "orange",
      color: "white",
    },
  };

  // Style commun pour les liens non sélectionnés (en mode sombre, le texte sera blanc et survol en gris foncé)
  const nonSelectedStyles = {
    backgroundColor: "transparent",
    color: isDark ? theme.palette.common.white : "inherit",
    "&:hover": {
      backgroundColor: isDark ? theme.palette.grey[600] : "lightgray",
      color: isDark ? theme.palette.common.white : "inherit",
    },
  };

  return (
    <Paper
      sx={{
        width: 320,
        maxWidth: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
        background: isDark ? theme.palette.background.paper : undefined,
      }}
    >
      {/* Section supérieure : Logo et Titre */}
      <div style={{ textAlign: "center", padding: "20px" }}>
        <img
          src="https://www.i-kiddy.com/images/i-kiddy-logo.png"
          alt="Logo"
          style={{ height: "60px", objectFit: "contain" }}
        />
        <Typography variant="h6" sx={{ mt: 1 }}>
          i-Kiddy
        </Typography>
      </div>

      <Divider />

      {/* Section des menus */}
      <MenuList sx={{ flexGrow: 1 }}>
        {/* Accueil */}
        <MenuItem
          onClick={() => directionPath("dashboard")}
          sx={{
            ...(section === "dashboard" ? selectedStyles : nonSelectedStyles),
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ListItemIcon>
              <HomeIcon
                fontSize="small"
                sx={{
                  color:
                    section === "dashboard"
                      ? "white"
                      : isDark
                      ? theme.palette.common.white
                      : "inherit",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Accueil" />
          </div>
        </MenuItem>

        {/* Factures */}
        <MenuItem
          onClick={() => directionPath("invoices")}
          sx={{
            ...(section === "invoices" ? selectedStyles : nonSelectedStyles),
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ListItemIcon>
              <ReceiptIcon
                fontSize="small"
                sx={{
                  color:
                    section === "invoices"
                      ? "white"
                      : isDark
                      ? theme.palette.common.white
                      : "inherit",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Factures" />
          </div>
          <Badge
            badgeContent={notifications.invoices}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor:
                  section === "invoices" ? "white" : "orange",
                color: section === "invoices" ? "gray" : "white",
              },
            }}
          />
        </MenuItem>

        {/* Paramètres */}
        <MenuItem
          onClick={() => directionPath("parameters/general")}
          sx={{
            ...(section === "parameters" ? selectedStyles : nonSelectedStyles),
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ListItemIcon>
              <SettingsIcon
                fontSize="small"
                sx={{
                  color:
                    section === "parameters"
                      ? "white"
                      : isDark
                      ? theme.palette.common.white
                      : "inherit",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Paramètres" />
          </div>
        </MenuItem>
        <Divider />
        <MenuSideOrganizer />
      </MenuList>

      {/* Section inférieure : Déconnexion */}
      <div>
        <Divider />
        <MenuItem
          onClick={() => handleLogout()}
          sx={{
            "&:hover": {
              backgroundColor: isDark ? theme.palette.grey[600] : "lightgray",
            },
          }}
        >
          <ListItemIcon>
            <LogoutIcon
              fontSize="small"
              sx={{ color: isDark ? theme.palette.common.white : "inherit" }}
            />
          </ListItemIcon>
          <ListItemText primary="Déconnexion" />
        </MenuItem>
      </div>
    </Paper>
  );
}