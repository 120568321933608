import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import LockIcon from '@mui/icons-material/Lock';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import EmailIcon from '@mui/icons-material/Email';

export default function MenuParameters() {
    const navigate = useNavigate(); // Permet de naviguer entre les routes
    const { tab } = useParams(); // Récupère le paramètre actuel (tab)

    // Fonction pour changer de section
    const handleMenuClick = (targetTab) => {
        navigate(`/parent/parameters/${targetTab}`); // Met à jour l'URL
    };

    return (
        <Paper
            sx={{
                width: 220,
                maxWidth: '100%',
                height: '100vh', // Prend toute la hauteur de l'écran
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // Sépare les sections haut/milieu/bas
                overflowY: 'auto',
            }}
        >
            {/* Section supérieure : Titre */}
            <div style={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ mt: 1 }}>
                    Paramètres
                </Typography>
            </div>

            {/* Divider entre le titre et les menus */}
            <Divider />

            {/* Section des menus */}
            <MenuList sx={{ flexGrow: 1 }}>
                <MenuItem
                    onClick={() => handleMenuClick('general')}
                    sx={{
                        backgroundColor: tab === 'general' ? 'orange' : 'transparent',
                        color: tab === 'general' ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: tab === 'general' ? 'darkorange' : 'lightgray',
                        },
                    }}
                >
                    <ListItemIcon>
                        <PersonIcon fontSize="small" sx={{ color: tab === 'general' ? 'white' : 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText>Info générales</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => handleMenuClick('address')}
                    sx={{
                        backgroundColor: tab === 'address' ? 'orange' : 'transparent',
                        color: tab === 'address' ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: tab === 'address' ? 'darkorange' : 'lightgray',
                        },
                    }}
                >
                    <ListItemIcon>
                        <HomeIcon fontSize="small" sx={{ color: tab === 'address' ? 'white' : 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText>Adresse / Téléphone</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => handleMenuClick('nameEdite')}
                    sx={{
                        backgroundColor: tab === 'nameEdite' ? 'orange' : 'transparent',
                        color: tab === 'nameEdite' ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: tab === 'nameEdite' ? 'darkorange' : 'lightgray',
                        },
                    }}
                >
                    <ListItemIcon>
                        <PersonIcon fontSize="small" sx={{ color: tab === 'nameEdite' ? 'white' : 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText>Nom et prénom</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => handleMenuClick('password')}
                    sx={{
                        backgroundColor: tab === 'password' ? 'orange' : 'transparent',
                        color: tab === 'password' ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: tab === 'password' ? 'darkorange' : 'lightgray',
                        },
                    }}
                >
                    <ListItemIcon>
                        <LockIcon fontSize="small" sx={{ color: tab === 'password' ? 'white' : 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText>Mot de passe</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => handleMenuClick('UpdateEmail')}
                    sx={{
                        backgroundColor: tab === 'UpdateEmail' ? 'orange' : 'transparent',
                        color: tab === 'UpdateEmail' ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: tab === 'UpdateEmail' ? 'darkorange' : 'lightgray',
                        },
                    }}
                >
                    <ListItemIcon>
                        <EmailIcon fontSize="small" sx={{ color: tab === 'UpdateEmail' ? 'white' : 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText>Adresse email</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => handleMenuClick('language')}
                    sx={{
                        backgroundColor: tab === 'language' ? 'orange' : 'transparent',
                        color: tab === 'language' ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: tab === 'language' ? 'darkorange' : 'lightgray',
                        },
                    }}
                >
                    <ListItemIcon>
                        <LanguageIcon fontSize="small" sx={{ color: tab === 'language' ? 'white' : 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText>Langue</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => handleMenuClick('privacy')}
                    sx={{
                        backgroundColor: tab === 'privacy' ? 'orange' : 'transparent',
                        color: tab === 'privacy' ? 'white' : 'inherit',
                        '&:hover': {
                            backgroundColor: tab === 'privacy' ? 'darkorange' : 'lightgray',
                        },
                    }}
                >
                    <ListItemIcon>
                        <SecurityIcon fontSize="small" sx={{ color: tab === 'privacy' ? 'white' : 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText>Confidentialité</ListItemText>
                </MenuItem>
            </MenuList>
        </Paper>
    );
}