import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import LockIcon from '@mui/icons-material/Lock';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import EmailIcon from '@mui/icons-material/Email';
import { useTheme } from '@mui/material/styles';

export default function MenuParameters() {
  const navigate = useNavigate(); // Permet de naviguer entre les routes
  const { tab } = useParams(); // Récupère le paramètre actuel (tab)
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  // Fonction pour changer de section
  const handleMenuClick = (targetTab) => {
    navigate(`/organizer/parameters/${targetTab}`); // Met à jour l'URL
  };

  // Style pour le lien sélectionné
  const selectedStyle = {
    backgroundColor: "orange",
    color: "white",
    '&:hover': {
      backgroundColor: "orange",
      color: "white",
    },
  };

  // Style pour les liens non sélectionnés
  const nonSelectedStyle = {
    backgroundColor: "transparent",
    color: isDark ? theme.palette.common.white : "inherit",
    '&:hover': {
      backgroundColor: isDark ? theme.palette.grey[600] : "lightgray",
      color: isDark ? theme.palette.common.white : "inherit",
    },
  };

  return (
    <Paper
      sx={{
        width: 220,
        maxWidth: '100%',
        height: '100vh', // Prend toute la hauteur de l'écran
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Sépare les sections haut/milieu/bas
        overflowY: 'auto',
      }}
    >
      {/* Section supérieure : Titre */}
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ mt: 1 }}>
          Paramètres
        </Typography>
      </div>

      {/* Divider entre le titre et les menus */}
      <Divider />

      {/* Section des menus */}
      <MenuList sx={{ flexGrow: 1 }}>
        <MenuItem
          onClick={() => handleMenuClick('general')}
          sx={ tab === 'general' ? selectedStyle : nonSelectedStyle }
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" sx={ tab === 'general' ? { color: 'white' } : { color: isDark ? theme.palette.common.white : 'inherit' } } />
          </ListItemIcon>
          <ListItemText primary="Info générales" />
        </MenuItem>

        <MenuItem
          onClick={() => handleMenuClick('address')}
          sx={ tab === 'address' ? selectedStyle : nonSelectedStyle }
        >
          <ListItemIcon>
            <HomeIcon fontSize="small" sx={ tab === 'address' ? { color: 'white' } : { color: isDark ? theme.palette.common.white : 'inherit' } } />
          </ListItemIcon>
          <ListItemText primary="Adresse / Téléphone" />
        </MenuItem>

        <MenuItem
          onClick={() => handleMenuClick('nameEdite')}
          sx={ tab === 'nameEdite' ? selectedStyle : nonSelectedStyle }
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" sx={ tab === 'nameEdite' ? { color: 'white' } : { color: isDark ? theme.palette.common.white : 'inherit' } } />
          </ListItemIcon>
          <ListItemText primary="Nom et prénom" />
        </MenuItem>

        <MenuItem
          onClick={() => handleMenuClick('password')}
          sx={ tab === 'password' ? selectedStyle : nonSelectedStyle }
        >
          <ListItemIcon>
            <LockIcon fontSize="small" sx={ tab === 'password' ? { color: 'white' } : { color: isDark ? theme.palette.common.white : 'inherit' } } />
          </ListItemIcon>
          <ListItemText primary="Mot de passe" />
        </MenuItem>

        <MenuItem
          onClick={() => handleMenuClick('UpdateEmail')}
          sx={ tab === 'UpdateEmail' ? selectedStyle : nonSelectedStyle }
        >
          <ListItemIcon>
            <EmailIcon fontSize="small" sx={ tab === 'UpdateEmail' ? { color: 'white' } : { color: isDark ? theme.palette.common.white : 'inherit' } } />
          </ListItemIcon>
          <ListItemText primary="Adresse email" />
        </MenuItem>

        <MenuItem
          onClick={() => handleMenuClick('language')}
          sx={ tab === 'language' ? selectedStyle : nonSelectedStyle }
        >
          <ListItemIcon>
            <LanguageIcon fontSize="small" sx={ tab === 'language' ? { color: 'white' } : { color: isDark ? theme.palette.common.white : 'inherit' } } />
          </ListItemIcon>
          <ListItemText primary="Langue" />
        </MenuItem>

        <MenuItem
          onClick={() => handleMenuClick('privacy')}
          sx={ tab === 'privacy' ? selectedStyle : nonSelectedStyle }
        >
          <ListItemIcon>
            <SecurityIcon fontSize="small" sx={ tab === 'privacy' ? { color: 'white' } : { color: isDark ? theme.palette.common.white : 'inherit' } } />
          </ListItemIcon>
          <ListItemText primary="Confidentialité" />
        </MenuItem>
      </MenuList>
    </Paper>
  );
}