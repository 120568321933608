import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  CircularProgress, 
  Autocomplete, 
  Typography, 
  Box 
} from '@mui/material';
import ttServices from "@tomtom-international/web-sdk-services";

const SearchAddress = ({ handleAddressSelect, defaultAddress, title='Adresse' }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Utilisation de la clé API depuis .env (assurez-vous que REACT_APP_TOMTOM_API_KEY est défini)
  const apiKey = "MhDpuDN3Yy6DWihRMvAfEf9JXOWs3r4Q";

  // Initialisation : afficher la valeur par défaut si fournie
  useEffect(() => {
    if (defaultAddress) {
      const defaultStr = `${defaultAddress.streetNumber || ""}, ${defaultAddress.streetName || ""}, ${defaultAddress.postalCode || ""}, ${defaultAddress.city || ""}`.trim();
      setInputValue(defaultStr);
    }
  }, [defaultAddress]);

  // Lancer la recherche dès que l'input dépasse 2 caractères
  useEffect(() => {
    let active = true;
    if (inputValue.length < 3) {
      setOptions([]);
      return;
    }
    setIsLoading(true);
    ttServices.services.fuzzySearch({
      key: apiKey,
      query: inputValue,
      language: "fr-FR",
      limit: 10,
    })
      .then((result) => {
        if (active) {
          // Ajout d'un index unique à chaque option
          const opts = (result.results || []).map((option, index) => ({
            ...option,
            __index: index,
          }));
          setOptions(opts);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la recherche d'adresse :", error);
      })
      .finally(() => {
        if (active) setIsLoading(false);
      });
    return () => {
      active = false;
    };
  }, [inputValue, apiKey]);

  // Lorsque l'utilisateur sélectionne une suggestion
  const handleChange = (event, newValue) => {
    if (newValue) {
      const { address: addr, position } = newValue;
      const selectedDetails = {
        streetNumber: addr.streetNumber || "",
        streetName: addr.streetName || "",
        city: addr.municipality || "",
        postalCode: addr.postalCode || "",
        country: addr.country || "",
        countryCode: addr.countryCode || "",
        latitude: position?.lat || null,
        longitude: position?.lng || null,
      };
      const updatedAddress = {
        number: selectedDetails.streetNumber,
        fullAddress: `${selectedDetails.streetName || ""}, ${selectedDetails.postalCode || ""}, ${selectedDetails.city || ""}`.trim(),
        details: selectedDetails,
      };
      setInputValue(updatedAddress.fullAddress);
      if (handleAddressSelect) {
        handleAddressSelect(updatedAddress);
      }
    }
  };

  return (
    <Autocomplete
    sx={{mt:4}}
      freeSolo
      autoComplete
      filterOptions={(x) => x} // Désactivation du filtrage interne car on utilise l'API
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return `${option.address.streetNumber || ""} ${option.address.streetName || ""}, ${option.address.postalCode || ""}, ${option.address.municipality || ""}`;
      }}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      loading={isLoading}
      renderOption={(props, option) => {
        // Extraire la prop "key" et utiliser une clé unique
        const { key, ...otherProps } = props;
        const addr = option.address;
        const uniqueKey = `${addr.streetNumber}-${addr.streetName}-${addr.postalCode}-${addr.municipality}-${option.__index}`;
        return (
          <li key={uniqueKey} {...otherProps}>
            <Box>
              <Typography variant="body1">
                {`${addr.streetNumber || ""} ${addr.streetName || ""}, ${addr.postalCode || ""}, ${addr.municipality || ""}`}
              </Typography>
              <Typography 
                variant="caption" 
                color="text.secondary" 
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <img 
                  src={`https://flagcdn.com/w20/${addr.countryCode?.toLowerCase()}.png`} 
                  alt={addr.country}
                  style={{ width: 20, height: 15, marginRight: 4 }}
                />
                {addr.country}
              </Typography>
            </Box>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label={title}
          size="small" 
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchAddress;