import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CircularProgress,
    Alert,
    Button,
    TextField,
    Box,
    Typography,
    Avatar,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import api from '../utils/api';

const ResendVerificationEmail = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [countdown, setCountdown] = useState(null); // Countdown for redirection
    const [retryAfter, setRetryAfter] = useState(null); // Retry time in case of 429 error
    const navigate = useNavigate();

    // Validate email
    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleResend = async () => {
        // Validate email
        if (!isValidEmail(email)) {
            setStatus('error');
            setMessage('Please enter a valid email address.');
            return;
        }

        setLoading(true); // Activate loader
        setStatus(''); // Reset status
        setMessage(''); // Reset message

        try {
            const response = await api.post('auth/resend-verify-email', { email });

            if (response.data.success) {
                // If request is successful
                setStatus('success');
                setMessage(response.data.message || 'A new verification link has been sent to your email address.');

                // Start a countdown of 5 seconds before redirect
                let countdownValue = 5;
                setCountdown(countdownValue);
                const interval = setInterval(() => {
                    countdownValue -= 1;
                    setCountdown(countdownValue);

                    if (countdownValue === 0) {
                        clearInterval(interval);
                        navigate('/login'); // Redirect to /login after countdown
                    }
                }, 1000);
            } else {
                // If backend returns success with an error message
                setStatus('error');
                setMessage(response.data.message || 'An error occurred.');
            }
        } catch (error) {
            // Handle specific errors
            const statusCode = error.response?.status;
            const errorMessage = error.response?.data?.message;

            if (statusCode === 429) {
                const retryAfterHeader = error.response.headers['retry-after'];
                const retryAfterSeconds = retryAfterHeader ? parseInt(retryAfterHeader, 10) : 300; // Default: 5 minutes (300s)

                setStatus('error');
                setMessage(
                    errorMessage ||
                    `You have exceeded the number of attempts. Please wait ${retryAfterSeconds} second${
                        retryAfterSeconds > 1 ? 's' : ''
                    } before trying again.`
                );

                setRetryAfter(retryAfterSeconds);

                // Start a countdown for retry wait time
                let retryCountdown = retryAfterSeconds;
                const interval = setInterval(() => {
                    retryCountdown -= 1;
                    setRetryAfter(retryCountdown);

                    if (retryCountdown === 0) {
                        clearInterval(interval);
                        setRetryAfter(null);
                    }
                }, 1000);
            } else if (statusCode === 403) {
                setStatus('error');
                setMessage(
                    errorMessage || 'You have reached the maximum number of requests. Please contact i-Kiddy support.'
                );
            } else {
                setStatus('error');
                setMessage(errorMessage || 'An error occurred while sending the link.');
            }
        } finally {
            setLoading(false); // Deactivate loader
        }
    };

    return (
        <Box
            sx={{
                marginTop: '50px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {/* i-Kiddy Logo */}
            <Avatar
                sx={{
                    width: 100,
                    height: 100,
                    marginBottom: 3,
                }}
                src="https://www.i-kiddy.com/images/i-kiddy-logo.png"
                alt="i-Kiddy Logo"
            />

            {/* Title */}
            <Typography variant="h4" sx={{ mb: 2 }}>
                Request a New Verification Link
            </Typography>

            {/* Email Input Field */}
            <TextField
                label="Your Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2, width: '300px' }}
                disabled={loading || retryAfter > 0} // Disable field if retry limit active
                placeholder="Enter your email address"
            />

            {/* Submit Button */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleResend}
                disabled={loading || !email || retryAfter > 0} // Disable button if retry active
                sx={{ mb: 2 }}
                startIcon={loading ? null : <EmailIcon />}
            >
                {loading ? <CircularProgress size={24} /> : 'Send Verification Link'}
            </Button>

            {/* Status Message */}
            {status && (
                <Alert severity={status} sx={{ mb: 2, width: '300px' }}>
                    {message}
                    {status === 'success' && countdown > 0 && (
                        <Typography sx={{ mt: 1 }}>
                            Redirecting in {countdown} second{countdown > 1 ? 's' : ''}...
                        </Typography>
                    )}
                </Alert>
            )}

            {/* Retry Countdown */}
            {retryAfter > 0 && (
                <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
                    You can try again in {retryAfter} second{retryAfter > 1 ? 's' : ''}.
                </Typography>
            )}

            {/* Footer */}
            <Typography variant="body2" sx={{ mt: 3, color: '#555' }}>
                Need help?{' '}
                <a href="https://www.i-kiddy.com/support" target="_blank" rel="noopener noreferrer" style={{ color: '#007BFF' }}>
                    Contact Support
                </a>
            </Typography>
        </Box>
    );
};

export default ResendVerificationEmail;