import { constants } from '../auth/_constants/constants';
import api from '../utils/api';

export const getActivities = (organizerId) => {
  return async (dispatch) => {
    try {
      const response = await api.get('organizer/activities', { params: { organizer_id: organizerId } });

      if (response.data.status) {
        dispatch({
          type: constants.GET_ACTIVITIES,
          payload: response.data.activities,
        });
      } else {
        dispatch({
          type: constants.GET_ACTIVITIES_ERR,
          payload: response.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: constants.GET_ACTIVITIES_ERR,
        payload: err.message,
      });
    }
  };
};