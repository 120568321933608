import React, { useState, useEffect } from 'react';
import {
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import api from '../utils/api';

// Liste des préfixes européens avec validations
const countryPrefixes = [
  { code: 'BE', name: 'Belgique', regex: /^\+32\s?((4\d{2}\s?\d{6})|(2\s?\d{6,7})|(1\d\s?\d{6}))$/ }, // Mobile: +32 4..., Fixe: +32 2...
  { code: 'FR', name: 'France', regex: /^\+33\s?(6|7|[1-5])\d{8}$/ }, // Mobile: +33 6..., Fixe: +33 1 à +33 5...
  { code: 'DE', name: 'Allemagne', regex: /^\+49\s?[1-9]\d{8,10}$/ }, // Mobile/Fixe
  { code: 'ES', name: 'Espagne', regex: /^\+34\s?[6-9]\d{8}$/ }, // Mobile/Fixe
  { code: 'IT', name: 'Italie', regex: /^\+39\s?\d{9,10}$/ }, // Mobile/Fixe
];

const PhoneNumberInput = ({ defaultPhoneNumber }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  // Initialiser le numéro de téléphone avec la valeur par défaut
  useEffect(() => {
    if (defaultPhoneNumber) {
      setPhoneNumber(defaultPhoneNumber);
    }
  }, [defaultPhoneNumber]);

  // Validation du numéro de téléphone
  const validatePhoneNumber = (number) => {
    return countryPrefixes.some((country) =>
      country.regex.test(number.replace(/\s+/g, ''))
    );
  };

  // Gestion de l'entrée du numéro
  const handlePhoneNumberChange = (event) => {
    const newPhoneNumber = event.target.value;
    setPhoneNumber(newPhoneNumber);
    setError('');
    setSuccessMessage('');

    // Activer le bouton si le numéro est valide et différent de la valeur initiale
    const isValid = validatePhoneNumber(newPhoneNumber);
    setCanSubmit(isValid && newPhoneNumber !== defaultPhoneNumber);
  };

  // Sauvegarde du numéro via le Back-End
  const handleSave = async () => {
    if (!validatePhoneNumber(phoneNumber)) {
      setError('Numéro de téléphone invalide.');
      return;
    }

    setIsLoading(true);
    setError('');
    setSuccessMessage('');

    const number = { phoneNumber };

    try {
      const token = localStorage.getItem('access_token'); // Récupérer le token
      const response = await api.post('/update-user-phone', number, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Numéro de téléphone enregistré avec succès.');
        setCanSubmit(false); // Désactiver le bouton après enregistrement
      } else {
        setError('Une erreur est survenue lors de l’enregistrement.');
      }
    } catch (error) {
      console.error(error.response?.data || error.message);
      setError('Erreur de communication avec le serveur.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box sx={{ padding: '0px 0px 30px 0px' }}>
        <Typography variant="h7" gutterBottom>
          Ajouter un numéro de téléphone
        </Typography>
      </Box>
      <Box sx={{  mx: 'auto', mb: 10 }}>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

        <TextField
          label="Numéro de téléphone"
          variant="outlined"
          fullWidth
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="+32 467872663"
          helperText="Exemple : +32 467872663 (mobile belge) ou +32 2 9282827 (fixe belge)"
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSave}
          disabled={!canSubmit || isLoading} // Désactiver le bouton selon les conditions
        >
          {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Enregistrer'}
        </Button>
      </Box>
    </Box>
  );
};

export default PhoneNumberInput;