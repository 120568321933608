import React from 'react';

// Exemples de composants à afficher

function AdminDashboard() {
  

  return (
    <div>
      AdminDashboard
    </div>
  );
}

export default AdminDashboard;