import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import MessageIcon from '@mui/icons-material/Message';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import Logout from '../../../functions/Logout';

export default function MenuSide() {
  const navigate = useNavigate();
  const { section } = useParams(); // Récupère la section depuis l'URL
  const [isDrawerOpen, setDrawerOpen] = React.useState(false); // État pour le drawer

  // Exemple de données pour les badges
  const notifications = {
    messages: 7,
    organizers: 10,
    organizerMarked: 3,
    activitiesMarked: 8,
    invoices: 4,
  };

  const directionPath = (path) => {
    navigate('/parent/' + path); // Redirige vers l'URL souhaitée
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen); // Inverse l'état d'ouverture du drawer
  };

  const { handleLogout } = Logout(); // Récupérer la fonction handleLogout

  return (
    <Paper
      sx={{
        width: 320,
        maxWidth: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: 'auto',
      }}
    >
      {/* Section supérieure : Logo et Titre */}
      <div style={{ textAlign: 'center', padding: '20px ' }}>
        <img
          src="https://www.i-kiddy.com/images/i-kiddy-logo.png"
          alt="Logo"
          style={{ height: '60px', objectFit: 'contain' }}
        />
        <Typography variant="h6" sx={{ mt: 1 }}>
          i-Kiddy
        </Typography>
      </div>

      {/* Divider entre le logo et les menus */}
      <Divider />

      {/* Section des menus */}
      <MenuList sx={{ flexGrow: 1 }}>
        {/* Accueil */}
        <MenuItem
          onClick={() => directionPath('dashboard')}
          sx={{
            backgroundColor: section === 'dashboard' ? 'orange' : 'transparent',
            color: section === 'dashboard' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'dashboard' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <HomeIcon fontSize="small" sx={{ color: section === 'dashboard' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Accueil</ListItemText>
          </div>
        </MenuItem>

        {/* Réservations */}
        <MenuItem
          onClick={() => {
            handleDrawerToggle();
          }}
          sx={{
            backgroundColor: section === 'basket' ? 'orange' : 'transparent',
            color: section === 'basket' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'basket' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <ShoppingBasketIcon fontSize="small" sx={{ color: section === 'basket' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Réservations</ListItemText>
          </div>
        </MenuItem>

        {/* Factures */}
        <MenuItem
          onClick={() => directionPath('invoices')}
          sx={{
            backgroundColor: section === 'invoices' ? 'orange' : 'transparent',
            color: section === 'invoices' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'invoices' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <ReceiptIcon fontSize="small" sx={{ color: section === 'invoices' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Factures</ListItemText>
          </div>
          <Badge
            badgeContent={notifications.invoices}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: section === 'invoices' ? 'white' : 'orange',
                color: section === 'invoices' ? 'gray' : 'white',
              },
            }}
          />
        </MenuItem>

        {/* Enfants */}
        <MenuItem
          onClick={() => directionPath('children')}
          sx={{
            backgroundColor: section === 'children' ? 'orange' : 'transparent',
            color: section === 'children' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'children' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <ChildCareIcon fontSize="small" sx={{ color: section === 'children' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Enfants</ListItemText>
          </div>
        </MenuItem>

        {/* Messages */}
        <MenuItem
          onClick={() => directionPath('messages')}
          sx={{
            backgroundColor: section === 'messages' ? 'orange' : 'transparent',
            color: section === 'messages' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'messages' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <MessageIcon fontSize="small" sx={{ color: section === 'messages' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Messages</ListItemText>
          </div>
          <Badge
            badgeContent={notifications.messages}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: section === 'messages' ? 'white' : 'orange',
                color: section === 'messages' ? 'gray' : 'white',
              },
            }}
          />
        </MenuItem>

        {/* Activités favorites */}
        <MenuItem
          onClick={() => directionPath('activityMarked')}
          sx={{
            backgroundColor: section === 'activityMarked' ? 'orange' : 'transparent',
            color: section === 'activityMarked' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'activityMarked' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <FavoriteIcon fontSize="small" sx={{ color: section === 'activityMarked' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Activités favorites</ListItemText>
          </div>
          <Badge
            badgeContent={notifications.activitiesMarked}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: section === 'activityMarked' ? 'white' : 'orange',
                color: section === 'activityMarked' ? 'gray' : 'white',
              },
            }}
          />
        </MenuItem>

        {/* Organisateurs */}
        <MenuItem
          onClick={() => directionPath('organizer')}
          sx={{
            backgroundColor: section === 'organizer' ? 'orange' : 'transparent',
            color: section === 'organizer' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'organizer' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <GroupIcon fontSize="small" sx={{ color: section === 'organizer' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Organisateurs</ListItemText>
          </div>
          <Badge
            badgeContent={notifications.organizers}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: section === 'organizer' ? 'white' : 'orange',
                color: section === 'organizer' ? 'gray' : 'white',
              },
            }}
          />
        </MenuItem>

        {/* Organisateurs favoris */}
        <MenuItem
          onClick={() => directionPath('organizerMarked')}
          sx={{
            backgroundColor: section === 'organizerMarked' ? 'orange' : 'transparent',
            color: section === 'organizerMarked' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'organizerMarked' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <FavoriteIcon fontSize="small" sx={{ color: section === 'organizerMarked' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Organisateurs favoris</ListItemText>
          </div>
          <Badge
            badgeContent={notifications.organizerMarked}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: section === 'organizerMarked' ? 'white' : 'orange',
                color: section === 'organizerMarked' ? 'gray' : 'white',
              },
            }}
          />
        </MenuItem>

        {/* Paramètres */}
        <MenuItem
          onClick={() => directionPath('parameters/general')}
          sx={{
            backgroundColor: section === 'parameters' ? 'orange' : 'transparent',
            color: section === 'parameters' ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: section === 'parameters' ? 'darkorange' : 'lightgray',
            },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" sx={{ color: section === 'parameters' ? 'white' : 'inherit' }} />
            </ListItemIcon>
            <ListItemText>Paramètres</ListItemText>
          </div>
        </MenuItem>
      </MenuList>

      {/* Section inférieure : Déconnexion */}
      <div>
        <Divider />
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Déconnexion</ListItemText>
        </MenuItem>
      </div>

      {/* Drawer pour les Réservations */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
      >
        <Box
          sx={{
            width: 400,
            height: '100%',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Réservations
          </Typography>
          <Typography variant="body1">
            Voici la liste des réservations. Vous pouvez ajouter, supprimer ou modifier vos réservations ici.
          </Typography>
        </Box>
      </Drawer>
    </Paper>
  );
}