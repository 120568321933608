import React, { useRef } from 'react';
import ContentSwitcher from './ContentSwitcher';
import MenuSide from './components/MenuSide';
import MenuParameters from './components/MenuParameters';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import '../../style/ParentDashboard.css'; // Importation du fichier CSS pour les animations
import { Container, Box } from '@mui/material';

function ParentDashboard() {
  const location = useLocation();
  const showMenuParameters = location.pathname.includes('/parameters');

  // Ref pour le MenuParameters
  const menuParametersRef = useRef(null);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {/* Partie gauche - MenuSide */}
      <div style={{ width: '280px', flexShrink: 0 }}> {/* Largeur fixe pour le menu principal */}
        <MenuSide />
      </div>

      {/* MenuParameters avec glissement horizontal */}
      <CSSTransition
        in={showMenuParameters} // Affiche uniquement si 'parameters' est dans l'URL
        timeout={300} // Durée de l'animation en ms
        classNames="slide" // Préfixe des classes CSS
        unmountOnExit // Retire le composant du DOM lorsqu'il est masqué
        nodeRef={menuParametersRef} // Utilisation de nodeRef pour éviter findDOMNode
      >
        <div ref={menuParametersRef} style={{ width: '220px', flexShrink: 0 }}> {/* Largeur fixe pour le menu paramètres */}
          <MenuParameters />
        </div>
      </CSSTransition>

      {/* Partie droite - ContentSwitcher */}
      <div style={{ flexGrow: 1, overflowY: 'auto' }}> {/* La partie droite occupe l'espace restant */}
        <Container
          maxWidth="md"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            // mt: 4,
          }}
        >
          <Box sx={{ width: '100%', paddingTop: 3 }}>
            <ContentSwitcher />
          </Box>
        </Container>
      </div>
    </div>
  );
}

export default ParentDashboard;