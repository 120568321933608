import React, { useState } from 'react';
import { Box, Typography, Button, Grid2, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import pour la traduction

export default function ChoiceType({ onChoice, selectedType }) {
    const [loading, setLoading] = useState(''); // État pour suivre quel bouton est en chargement
    const { t } = useTranslation(); // Hook pour accéder aux traductions

    const handleChoice = (type) => {
        setLoading(type); // Définir le bouton sélectionné comme "chargement"
        setTimeout(() => {
            setLoading(''); // Réinitialiser le chargement après 3 secondes
            onChoice(type); // Appeler la fonction parent pour enregistrer le choix
        }, 2000);
    };

    return (
        <Box>
            <Typography 
                variant="h7" 
                align="center" 
                sx={{ mb: 2, textTransform: 'capitalize' }}
            >
                {t('choiceType.title')} {/* Texte traduit */}
            </Typography>
            <Grid2 container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
    <Grid2  xs={12} md={5}> {/* `` doit être un booléen */}
        <Box
            sx={{
                border: selectedType === 'parent' ? '2px solid #1976d2' : '1px solid #ddd',
                borderRadius: '8px',
                padding: 3,
                textAlign: 'center',
                backgroundColor: selectedType === 'parent' ? '#e3f2fd' : 'transparent',
            }}
        >
            <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {t('choiceType.parentTitle')}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, textTransform: 'none' }}>
                {t('choiceType.parentDescription')}
            </Typography>
            <Button
                variant={selectedType === 'parent' ? 'contained' : 'outlined'}
                onClick={() => handleChoice('parent')}
                sx={{ mt: 2, textTransform: 'capitalize' }}
                disabled={loading === 'parent'}
            >
                {loading === 'parent' ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    t('choiceType.parentButton')
                )}
            </Button>
        </Box>
    </Grid2>
    <Grid2  xs={12} md={5}> {/* Assurez-vous également ici */}
        <Box
            sx={{
                border: selectedType === 'organizer' ? '2px solid #1976d2' : '1px solid #ddd',
                borderRadius: '8px',
                padding: 3,
                textAlign: 'center',
                backgroundColor: selectedType === 'organizer' ? '#e3f2fd' : 'transparent',
            }}
        >
            <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {t('choiceType.organizerTitle')}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, textTransform: 'none' }}>
                {t('choiceType.organizerDescription')}
            </Typography>
            <Button
                variant={selectedType === 'organizer' ? 'contained' : 'outlined'}
                onClick={() => handleChoice('organizer')}
                sx={{ mt: 2, textTransform: 'capitalize' }}
                disabled={loading === 'organizer'}
            >
                {loading === 'organizer' ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    t('choiceType.organizerButton')
                )}
            </Button>
        </Box>
    </Grid2>
</Grid2>
        </Box>
    );
}