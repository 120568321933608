import React, { useState } from 'react';
import { 
  Button, 
  CssBaseline, 
  TextField,
  FormControlLabel,
  Checkbox,
  Link, 
  Box, 
  Grid,
  Typography,
  Alert,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { validatePassword } from '../utils/Regex';
import { useTranslation } from 'react-i18next';

export default function Register({ role }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [birthDate, setBirthDate] = useState(null);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [gender, setGender] = useState('female');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [formError, setFormError] = useState('');
  const [termsError, setTermsError] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setFormError('');
    if (!validateEmail(value)) {
      setPasswordError(t('login.invalidEmail'));
    } else {
      setPasswordError('');
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    if (!validatePassword(value)) {
      setPasswordError(t('register.passwordValidationError'));
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    if (password && value !== password) {
      setPasswordError(t('register.passwordMismatchError'));
    } else {
      setPasswordError('');
    }
  };

  const handleTermsChange = (event) => {
    setAcceptTerms(event.target.checked);
    setTermsError(!event.target.checked);
  };

  const isSubmitDisabled = () => {
    return (
      !!passwordError ||
      !password ||
      !confirmPassword ||
      password !== confirmPassword ||
      !acceptTerms ||
      !firstname ||
      !lastname ||
      !email ||
      !birthDate
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!role) {
      alert(t("register.chooseAccountType"));
      return;
    }

    if (!acceptTerms) {
      setTermsError(true);
      setFormError(t('register.termsError'));
      return;
    }

    if (passwordError || password !== confirmPassword || !firstname || !lastname || !email || !birthDate) {
      setFormError(t('register.formError'));
      setAlertSeverity('error');
      return;
    }

    setIsLoading(true);
    const formattedBirthDate = format(birthDate, 'yyyy-MM-dd');

    const userData = {
      firstname,
      lastname,
      gender,
      birthday: formattedBirthDate,
      termsAccepted: acceptTerms,
      email,
      password,
      role, // Type de compte
    };

    try {
      const response = await api.post('auth/register', userData);
      if (response.data.success) {
        setSuccessMessage(t('register.successMessage'));
        setAlertSeverity('success');
        setFormError('');
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } else {
        setFormError(t('register.unexpectedError'));
        setAlertSeverity('error');
        setIsLoading(false);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors
        ? Object.values(error.response.data.errors).flat().join(', ')
        : t('register.unexpectedError');
      setFormError(errorMessage);
      setAlertSeverity('error');
      setIsLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <Grid container component="main" >
        <CssBaseline />
        <Grid
        //   xs={12}
        //   md={12}
          sx={{
            // flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'center',
            // mx: 'auto',
            // maxWidth: '400px',
            // width: '100%',
            p:0,
            m:0
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              p: 4,
            }}
          >
            {successMessage && (
              <Alert severity="success" sx={{ width: '100%' }}>
                {successMessage}
              </Alert>
            )}
            {formError && (
              <Alert severity={alertSeverity} sx={{ width: '100%', mt: 1 }}>
                {formError}
              </Alert>
            )}

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
              {/* Champs prénom et nom sur la même ligne */}
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={t('register.firstName')}
                    name="firstName"
                    autoComplete="firstName"
                    autoFocus
                    size="small"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label={t('register.lastName')}
                    name="lastName"
                    autoComplete="lastName"
                    size="small"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    sx={{ width: '100%' }}
                    />
                </Grid>
                </Grid>

              <FormControl component="fieldset" sx={{ mt: 2 }}>
                <FormLabel component="legend">{t('register.gender')}</FormLabel>
                <RadioGroup row name="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                  <FormControlLabel value="female" control={<Radio />} label={t('register.female')} />
                  <FormControlLabel value="male" control={<Radio />} label={t('register.male')} />
                  <FormControlLabel value="other" control={<Radio />} label={t('register.other')} />
                </RadioGroup>
              </FormControl>

              <DatePicker
                label={t('register.birthDate')}
                value={birthDate}
                onChange={(newValue) => setBirthDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    margin: 'normal',
                  },
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('register.emailAddress')}
                name="email"
                autoComplete="email"
                size="small"
                value={email}
                onChange={handleEmailChange}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('register.password')}
                type="password"
                id="password"
                autoComplete="new-password"
                size="small"
                value={password}
                onChange={handlePasswordChange}
                error={!!passwordError}
                helperText={passwordError}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={t('register.confirmPassword')}
                type="password"
                id="confirmPassword"
                autoComplete="confirmPassword"
                size="small"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={!!passwordError}
                helperText={passwordError}
              />

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acceptTerms}
                      onChange={handleTermsChange}
                      color={termsError ? 'error' : 'primary'}
                    />
                  }
                  label={
                    <Typography sx={{ color: !acceptTerms ? 'red' : 'inherit' }}>
                      {t('register.acceptTerms')}{' '}
                      <Link href="/conditions" target="_blank" sx={{ textDecoration: 'none' }}>
                        {t('register.termsLink')}
                      </Link>
                    </Typography>
                  }
                />
                {!acceptTerms && (
                  <Typography variant="caption" sx={{ color: 'red', ml: 2 }}>
                    {t('register.required')}
                  </Typography>
                )}
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                disabled={isSubmitDisabled() || isLoading}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : t('register.submit')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}