import React, { useRef } from 'react';
import ContentSwitcher from './ContentSwitcher';
import MenuSide from './components/MenuSide';
import MenuParameters from './components/MenuParameters';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import '../../style/ParentDashboard.css'; // Importation du fichier CSS pour les animations
import { Container, Box } from '@mui/material';
import MenuOrganizer from './components/MenuOrganizer';

function OrganizerDashboard() {
    const location = useLocation();
    const showMenuParameters = location.pathname.includes('/parameters');
    const showMenuOrganizer = location.pathname.includes('/o/');

    // Refs distinctes pour chaque transition
    const menuParametersRef = useRef(null);
    const menuOrganizerRef = useRef(null);

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            {/* Partie gauche - MenuSide */}
            <div style={{ width: '280px', flexShrink: 0 }}>
                <MenuSide />
            </div>

            {/* MenuParameters (affiché si l'URL contient '/parameters') */}
            <CSSTransition
                in={showMenuParameters}
                timeout={300}
                classNames="slide"
                unmountOnExit
                nodeRef={menuParametersRef}
            >
                <div ref={menuParametersRef} style={{ width: '220px', flexShrink: 0 }}>
                    <MenuParameters />
                </div>
            </CSSTransition>

            {/* MenuSideOrganizer (affiché si l'URL contient '/o') */}
            <CSSTransition
                in={showMenuOrganizer}
                timeout={300}
                classNames="slide"
                unmountOnExit
                nodeRef={menuOrganizerRef}
            >
                <div ref={menuOrganizerRef} style={{ width: '220px', flexShrink: 0 }}>
                    <MenuOrganizer />
                </div>
            </CSSTransition>

            {/* Partie droite - ContentSwitcher */}
            <div style={{ flexGrow: 1, overflowY: 'auto' }} >
                <Container
                    // maxWidth="md"
                    // sx={{
                    //     display: 'flex',
                    //     justifyContent: 'center',
                    //     flexDirection: 'column',
                    //     alignItems: 'center',
                    //     m: 0,
                    //     p: 0
                    // }}
                >
                    <Box sx={{ width: '100%', paddingTop: 3 }}>
                        <ContentSwitcher />
                    </Box>
                </Container>
            </div>
        </div>
    );
}

export default OrganizerDashboard;