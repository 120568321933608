// ActivityDisplayView.jsx
import React from 'react';
import { Box, Paper, Typography, Divider } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

// Fonction utilitaire pour formater une date en "DD/MM/YYYY"
const formatDate = (dateStr) => dateStr ? dayjs(dateStr).format("DD/MM/YYYY") : '-';

// Fonction utilitaire pour formater une heure en "HH:mm"
// On suppose que la chaîne de temps est au format "HH:mm:ss"
const formatTime = (timeStr) => timeStr ? timeStr.slice(0, 5) : '-';

const ActivityDisplayView = () => {
  const activity = useSelector((state) => state.activities.activity);

  if (!activity) {
    return (
      <Box sx={{ width: '100%'}}>
        <Typography variant="body1">Aucune activité trouvée.</Typography>
      </Box>
    );
  }

  // Détermine le statut de publication
  const publishedStatus = activity.isPublished ? "Published" : "Draft";

  return (
    <Box sx={{ width: '100%'}}>
      <Typography variant="h5" gutterBottom>
        {activity.activityName}
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">
          <strong>Type d'activité:</strong> {activity.activityType}
        </Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">
          <strong>Description:</strong> {activity.description || '-'}
        </Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">
          <strong>Période d'inscription:</strong> {formatDate(activity.registrationStart)} – {formatDate(activity.registrationEnd)}
        </Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">
          <strong>Période de l'activité:</strong> {formatDate(activity.activityStart)} – {formatDate(activity.activityEnd)}
        </Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">
          <strong>Horaire quotidien:</strong> {formatTime(activity.dailyStartTime)} – {formatTime(activity.dailyEndTime)}
        </Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">
          <strong>Localisation:</strong> {activity.streetName} {activity.streetNumber}, {activity.postalCode} {activity.city}, {activity.country}
        </Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">
          <strong>Statut:</strong> {publishedStatus}
        </Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body1">
          <strong>Créé le:</strong> {formatDate(activity.created_at)}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body1">
          <strong>Mis à jour le:</strong> {formatDate(activity.updated_at)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ActivityDisplayView;