import { constants } from "../auth/_constants/constants";

const initialState = {
  activities: [],
  activity: null,
  error: null,
};

export default function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_ACTIVITIES:
      return {
        ...state,
        activities: action.payload || [],
        error: null,
      };
      case constants.GET_ACTIVITY:
        const newActivity = action.payload;
        const activityExists = state.activities.some(activity => activity.id === newActivity.id);
        return {
          ...state,
          activities: activityExists
            ? state.activities.map(activity =>
                activity.id === newActivity.id ? newActivity : activity
              )
            : [...state.activities, newActivity],
          activity: newActivity,
          error: null,
        };
        case constants.UPDATE_POSTER:
          return {
              ...state,
              activity: {
                  ...state.activity,
                  poster: action.payload.poster, // Met à jour l'avatar
              },
              activities: state.activities.map((activity) =>
                  activity.id === action.payload.id ? { ...activity, poster: action.payload.poster } : activity // Met l'avatar à null si l'ID correspond
              )
          };
      case constants.REMOVE_POSTER:
          return {
              ...state,
              activity: {
                  ...state.activity,
                  poster: null, // Met à jour l'avatar
              },
              activities: state.activities.map((activity) =>
                  activity.id === action.payload.id ? { ...activity, poster: null } : activity // Met l'avatar à null si l'ID correspond
              )
              
          };
    default:
      return state;
  }
}