// src/components/MenuOrganizer.jsx
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import AvatarEditorModal from "../../../components/AvatarEditorModal";
import { Avatar, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";

export default function MenuOrganizer() {
  const navigate = useNavigate();
  const { tab, tab2 } = useParams(); // tab2 représente la section active du menu
  const organizer = useSelector((state) => state.organizers.organizer);
  const [openAvatarEditorModal, setOpenAvatarEditorModal] = useState(false);
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const handleMenuClick = (targetTab) => {
    navigate(`/organizer/o/${tab}/${targetTab}`);
  };

  // Définition des styles pour les éléments sélectionnés et non sélectionnés
  const selectedStyle = {
    backgroundColor: "orange",
    color: "white",
    "&:hover": {
      backgroundColor: "orange",
      color: "white",
    },
  };

  const nonSelectedStyle = {
    backgroundColor: "transparent",
    color: isDark ? theme.palette.common.white : "inherit",
    "&:hover": {
      backgroundColor: isDark ? theme.palette.grey[600] : "lightgray",
      color: isDark ? theme.palette.common.white : "inherit",
    },
  };

  return (
    <Paper
      sx={{
        width: 220,
        maxWidth: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      {/* Section supérieure : Avatar et Organisation */}
      <div style={{ textAlign: "center", padding: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Avatar
            src={
              organizer?.logo
                ? `https://www.i-kiddy.com/images/logo/${organizer.logo}`
                : ""
            }
            sx={{
              width: 100,
              height: 100,
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() => setOpenAvatarEditorModal(true)}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: -5,
              right: "calc(50% - 12px)",
              width: 24,
              height: 24,
              backgroundColor: "white",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 0px 6px rgba(0,0,0,0.2)",
              cursor: "pointer",
            }}
            onClick={() => setOpenAvatarEditorModal(true)}
          >
            <EditIcon fontSize="small" sx={{ color: "#1976d2" }} />
          </Box>
        </Box>
        <AvatarEditorModal
          typePathBD="logo"
          id={organizer?.id}
          currentAvatar={organizer?.logo}
          open={openAvatarEditorModal}
          onClose={() => setOpenAvatarEditorModal(false)}
        />
        <Typography variant="h6" sx={{ mt: 1 }}>
          {organizer?.organizationName}
        </Typography>
      </div>
      <Divider />

      {/* Section des menus */}
      <MenuList sx={{ flexGrow: 1 }}>
        <MenuItem
          onClick={() => handleMenuClick("info-organizer")}
          sx={tab2 === "info-organizer" ? selectedStyle : nonSelectedStyle}
        >
          <ListItemText primary="Info générales" />
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuClick("activities")}
          sx={tab2 === "activities" ? selectedStyle : nonSelectedStyle}
        >
          <ListItemText primary="Activités" />
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuClick("Invoice")}
          sx={tab2 === "Invoice" ? selectedStyle : nonSelectedStyle}
        >
          <ListItemText primary="Factures" />
        </MenuItem>
      </MenuList>
    </Paper>
  );
}