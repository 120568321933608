import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Switch,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import api from "../../../utils/api";

export default function ChildMedicalModal({ name, childId, open, onClose }) {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState({});
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successAnimation, setSuccessAnimation] = useState({});

  
  const fetchMedicalData = useCallback(async () => {
    setLoading(true);
    setError(""); // Reset erreur avant la requête

    try {
      const [questionsResponse, answersResponse] = await Promise.all([
        api.get("parent/medical-questions"),
        api.get(`parent/medical-answers/${childId}`),
      ]);

      const fetchedAnswers = {};
      questionsResponse.data.forEach((q) => {
        fetchedAnswers[q.id] = {
          value: Boolean(answersResponse.data[q.id]), // Convertit en boolean pour éviter les erreurs
        };
      });

      setQuestions(questionsResponse.data);
      setAnswers(fetchedAnswers);
    } catch (error) {
      console.error("Erreur lors du chargement :", error);
      setError("Erreur lors du chargement des questions et réponses.");
    } finally {
      setLoading(false);
    }
  }, [childId]);

  /**
   * 🔥 Charge les données dès que `childId` et `open` changent
   */
  useEffect(() => {
    if (childId && open) {
      fetchMedicalData();
    }
  }, [childId, open, fetchMedicalData]); // ✅ Ajout de `fetchMedicalData` pour éviter le warning ESLint

  /**
   * 🔥 Met à jour la réponse et l'enregistre immédiatement
   */
  const handleChange = async (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: { ...prev[questionId], value },
    }));

    setSaving((prev) => ({ ...prev, [questionId]: true }));

    try {
      await api.post("parent/medical-answers", {
        child_id: childId,
        answers: [{ question_id: questionId, answer: value }],
      });

      setSuccessAnimation((prev) => ({ ...prev, [questionId]: true }));
      setSnackbarOpen(true);
      setTimeout(() => {
        setSuccessAnimation((prev) => ({ ...prev, [questionId]: false }));
      }, 1500);
    } catch (error) {
      console.error("Erreur de sauvegarde :", error);
      setError("Erreur lors de l'enregistrement. Veuillez réessayer.");

      setAnswers((prev) => ({
        ...prev,
        [questionId]: { ...prev[questionId], value: !value },
      }));
    } finally {
      setSaving((prev) => ({ ...prev, [questionId]: false }));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Fiche Médicale de {name}</DialogTitle>
      <DialogContent dividers>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          questions.map((q) => (
            <Box
              key={q.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #ddd",
                pb: 1,
                transition: "background-color 0.3s ease",
                backgroundColor: successAnimation[q.id] ? "#e8f5e9" : "transparent",
                minHeight: "40px",
              }}
            >
              <Typography variant="body1">{q.question_text}</Typography>

              <Box sx={{ display: "flex", alignItems: "center", minHeight: "40px" }}>
                {saving[q.id] ? (
                  <CircularProgress size={20} />
                ) : (
                  <Switch
                    checked={Boolean(answers[q.id]?.value)} // Convertit en boolean
                    onChange={(e) => handleChange(q.id, e.target.checked)}
                    color="primary"
                    disabled={saving[q.id]}
                  />
                )}
                <Typography sx={{ ml: 1 }}>
                  {answers[q.id]?.value ? "Oui" : "Non"}
                </Typography>
              </Box>
            </Box>
          ))
        )}
      </DialogContent>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Réponse enregistrée avec succès !"
      />
    </Dialog>
  );
}