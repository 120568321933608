import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/'); // Redirect to the home page
    };

    return (
        <div style={styles.container}>
            {/* i-Kiddy Logo */}
            <img
                src="https://www.i-kiddy.com/images/i-kiddy-logo.png"
                alt="i-Kiddy Logo"
                style={styles.logo}
            />

            {/* 404 Title with animation */}
            <h1 style={styles.errorTitle}>404</h1>
            <p style={styles.errorMessage}>Oops! The page you are looking for does not exist.</p>
            {/* Back Button */}
            <button style={styles.redirectButton} onClick={handleRedirect}>
                Back to Home
            </button>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '50px',
        fontFamily: 'Arial, sans-serif',
    },
    logo: {
        width: '150px',
        marginBottom: '20px',
    },
    errorTitle: {
        fontSize: '120px',
        color: '#FF6B6B',
        margin: '0',
        animation: 'pulse 1.5s infinite',
    },
    errorMessage: {
        fontSize: '18px',
        color: '#333',
        margin: '20px 0',
    },
    redirectButton: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#007BFF',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
};

// Adding CSS animation for the 404 title
const keyframes = `
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
`;

// Injecting the animation into the DOM
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default NotFound;