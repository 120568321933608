import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en';
import fr from '../locale/fr';
import nl from '../locale/nl';

const savedLanguage = localStorage.getItem('i18nextLng') || 'fr'; // Charger la langue enregistrée ou utiliser 'fr' par défaut

i18n
  .use(initReactI18next) // Connecte i18next avec react-i18next
  .init({
    resources: {
      nl: { translation: nl },
      fr: { translation: fr },
      en: { translation: en },
    },
    lng: savedLanguage, // Charger la langue enregistrée
    fallbackLng: 'fr', // Langue à utiliser si la langue choisie n'est pas disponible
    interpolation: {
      escapeValue: false, // React se charge déjà de l'échappement
    },
    detection: {
      // Persister la langue choisie dans le localStorage
      order: ['localStorage', 'navigator'], // Priorité : localStorage puis navigateur
      caches: ['localStorage'], // Stocker dans le localStorage
    },
  });

// Persister manuellement la langue lors du changement
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('i18nextLng', lng); // Enregistrer la langue dans le localStorage
});

export default i18n;