export const cropImage = async (imageSrc, crop, rotation) => {
    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (err) => reject(err));
        image.setAttribute("crossOrigin", "anonymous"); // Résoudre les problèmes CORS
        image.src = url;
      });
  
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
  
    const { width, height } = crop;
    canvas.width = width;
    canvas.height = height;
  
    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      width,
      height,
      0,
      0,
      width,
      height
    );
  
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        const file = new File([blob], `avatar_${Date.now()}.jpeg`, { type: "image/jpeg" });
        resolve(file);
      }, "image/jpeg");
    });
  };