// src/pages/ActivityManagement.jsx
import React from 'react';
import { Box } from '@mui/material';
import BasicInfoTab from './components/BasicInfoTab';
import { useParams } from 'react-router-dom';
import TabEditeActivity from './components/TabEditeActivity';
import ActivityDisplayView from './components/ActivityDisplayView';

const EditeActvity = () => {
  const { tab3 } = useParams();

  const renderComponent = () => {
    switch (tab3) {
      case "new":
        return <BasicInfoTab />;
      case "edit":
        return <TabEditeActivity />
      case "view":
        return <ActivityDisplayView />
      default:
        return <div>404</div>;
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: "center" }}>
      {renderComponent()}
    </Box>
  );
};

export default EditeActvity;