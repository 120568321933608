import { constants } from '../auth/_constants/constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = {
    user,
    adress: '',
    phoneNumber: '',
    message: null,
    status: true,
    err: null,
    type: null,
    isAuthenticated: !!user, // Détermine si l'utilisateur est authentifié au départ
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_USER:
            return {
                ...state,
                user: action.payload.user,
            };
        case constants.EDITE_NAME_USER:
            return {
                ...state,
                user: action.payload.user,
                status: true,
                err: null, // Réinitialiser l'erreur
            };
        case constants.GET_USER_ERR:
            return {
                ...state,
                status: false,
                err: action.payload,
            };
        case constants.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };
        case constants.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                adress: null, // Réinitialise aussi l'adresse
            };
        case constants.UPDATE_USER_AVATAR:
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar: action.payload.avatar, // Met à jour l'avatar
                },
            };
        case constants.REMOVE_USER_AVATAR:
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar: null, // Supprime l'avatar en mettant la valeur à null
                },
            };
        case constants.UPDATE_USER_EMAIL:
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.payload.email, // Met à jour l'email
                },
            };
        case constants.UPDATE_USER_ADRESS:
            return {
                ...state,
                adress: action.payload.adress, 
                phoneNumber: action.payload.adress.phoneNumber, 
            };
        case constants.UPDATE_USER_NAME:
            return {
                ...state,
                user: {
                    ...state.user,
                firstname: action.payload.firstname, 
                lastname: action.payload.lastname,
                }, 
            };
        default:
            return state;
    }
}