// src/pages/organizers/components/formActivity/components/BasicInfoTab.jsx
import React, { useState, useEffect } from 'react';
import { 
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  Alert
} from '@mui/material';
import SearchAddress from '../../../../../components/SearchAddress';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { constants } from '../../../../../auth/_constants/constants';
import api from '../../../../../utils/api';
import AvatarEditorModal from '../../../../../components/AvatarEditorModal';

const defaultAddress = {
  streetNumber: "",
  streetName: "",
  postalCode: "",
  city: "",
  country: "",
  countryCode: "",
  latitude: null,
  longitude: null,
};

const BasicInfoTab = () => {
  const { section, tab, tab2, tab3 } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Récupérer l'activité depuis Redux (mode edit)
  const reduxActivity = useSelector((state) => state.activities.activity);
  // États locaux pour le formulaire
  const [activityName, setActivityName] = useState('');
  const [activityType, setActivityType] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState({ ...defaultAddress });
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [openAvatarEditorModal, setOpenAvatarEditorModal] = useState(false);

  // Remplir le formulaire en mode "edit"
  useEffect(() => {
    if (tab3 === 'edit' && reduxActivity) {
      const activityData = {
        activityName: reduxActivity.activityName || '',
        activityType: reduxActivity.activityType || '',
        description: reduxActivity.description || '',
        address: {
          streetNumber: reduxActivity.streetNumber || "",
          streetName: reduxActivity.streetName || "",
          postalCode: reduxActivity.postalCode || "",
          city: reduxActivity.city || "",
          country: reduxActivity.country || "",
          countryCode: reduxActivity.countryCode || "",
          latitude: reduxActivity.latitude || null,
          longitude: reduxActivity.longitude || null,
        },
        cover: reduxActivity.poster || '',
      };

      setActivityName(activityData.activityName);
      setActivityType(activityData.activityType);
      setDescription(activityData.description);
      setAddress(activityData.address);
      setInitialData(activityData);
    }
  }, [tab3, reduxActivity]);

  // Vérifier si des modifications ont été apportées
  useEffect(() => {
    if (!initialData) return;
    const isChanged = 
      activityName !== initialData.activityName ||
      activityType !== initialData.activityType ||
      description !== initialData.description ||
      JSON.stringify(address) !== JSON.stringify(initialData.address);
    setIsModified(isChanged);
  }, [activityName, activityType, description, address, initialData]);

  const handleAddressSelect = (selectedAddress) => {
    setAddress(selectedAddress?.details || defaultAddress);
  };

  const isValid = () => {
    return activityName && activityType && description;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    setSuccessMsg('');

    if (!isValid()) {
      setErrorMsg('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const addr = address || defaultAddress;
    const formData = {
      id: tab3 === 'edit' ? reduxActivity?.id : null,
      organizer_id: reduxActivity?.organizer_id || null,
      activityName,
      activityType,
      description,
      streetNumber: addr.streetNumber,
      streetName: addr.streetName,
      postalCode: addr.postalCode,
      city: addr.city,
      country: addr.country,
      countryCode: addr.countryCode,
      latitude: addr.latitude,
      longitude: addr.longitude,
    };

    try {
      const endpoint = tab3 === 'edit' ? "organizer/update-activity" : "organizer/new-activity";
      const response = await api.post(endpoint, formData);

      if (response.data.success) {
        setSuccessMsg(
          response.data.message || (tab3 === 'edit' ? "Activité modifiée avec succès." : "Activité créée avec succès.")
        );
        dispatch({
          type: constants.GET_ACTIVITY,
          payload: response.data.activity,
        });
        if (tab3 !== 'edit') {
          navigate(`/organizer/${section}/${tab}/${tab2}/edit/${response.data.activity.id}`);
        }
        setIsModified(false);
      } else {
        setErrorMsg(response.data.message || 'Une erreur est survenue.');
      }
    } catch (error) {
      setErrorMsg("Une erreur est survenue lors de l'enregistrement de l'activité.");
      console.error(error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {errorMsg && <Alert severity="error" sx={{ mb: 2 }}>{errorMsg}</Alert>}
      {successMsg && <Alert severity="success" sx={{ mb: 2 }}>{successMsg}</Alert>}
      {
        tab3 === "edit" ?
      <Box sx={{ position: 'relative', mb: 2 }}>
        <img
          src={
            reduxActivity?.poster 
              ? `https://www.i-kiddy.com/images/poster/${reduxActivity?.poster}`
              : "https://www.i-kiddy.com/images/poster/default-poster.jpeg"
          }
          alt="Cover"
          style={{ width: '100%', height: '200px', objectFit: 'cover' }}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => setOpenAvatarEditorModal(true)}
          sx={{ position: 'absolute', top: 10, right: 10 }}
        >
          Cliquez ici pour changer la cover
        </Button>
      </Box> : ""
      }

      {/* Champs du formulaire */}
      <TextField
        name="activityName"
        label="Nom de l'activité"
        value={activityName}
        onChange={(e) => setActivityName(e.target.value)}
        fullWidth
        required
        size="small"
        margin="normal"
      />

      <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend">Type d'activité</FormLabel>
        <ToggleButtonGroup
          value={activityType}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setActivityType(newValue);
            }
          }}
          size="small"
          fullWidth
          sx={{ mt: 1 }}
        >
          <ToggleButton value="stage" sx={{ textTransform: 'capitalize' }}>
            Stage
          </ToggleButton>
          <ToggleButton value="activiteparascolaire" sx={{ textTransform: 'capitalize' }}>
            Parascolaire
          </ToggleButton>
          <ToggleButton value="excursion" sx={{ textTransform: 'capitalize' }}>
            Excursion
          </ToggleButton>
          <ToggleButton value="evenement" sx={{ textTransform: 'capitalize' }}>
            Événement
          </ToggleButton>
          <ToggleButton value="aidescolaire" sx={{ textTransform: 'capitalize' }}>
            Aide scolaire
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>

      <SearchAddress 
        handleAddressSelect={handleAddressSelect} 
        defaultAddress={address}
        title="Lieu de l'activité"
      />

      <TextField
        name="description"
        label="Description de l'activité"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        multiline
        rows={4}
        size="small"
        margin="normal"
        sx={{ mt: 4 }}
      />

      <Box sx={{ mt: 2 }}>
        <Button type="submit" variant="contained" fullWidth disabled={!isValid() || (tab3 === 'edit' && !isModified)}>
          {tab3 === 'edit' ? "Mettre à jour l'activité" : "Créer l'activité"}
        </Button>
      </Box>

      <AvatarEditorModal
        typePathBD="poster"
        id={reduxActivity?.id}
        currentAvatar={reduxActivity?.poster}
        open={openAvatarEditorModal}
        onClose={() => setOpenAvatarEditorModal(false)}
        format="16/9"
      />
    </Box>
  );
};

export default BasicInfoTab;