import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment, Alert, CircularProgress, Card } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import api from '../utils/api';
import { validatePassword } from '../utils/Regex';

export default function UpdatePassword() {
    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswords((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: '' }));
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const handleSave = async () => {
        const newErrors = {};
    
        if (!validatePassword(passwords.newPassword)) {
            newErrors.newPassword = 'Le mot de passe doit respecter les critères de sécurité.';
        }
    
        if (passwords.newPassword !== passwords.confirmPassword) {
            newErrors.confirmPassword = 'Les mots de passe ne correspondent pas.';
        }
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        const userData = {
            currentPassword: passwords.currentPassword,
            newPassword: passwords.newPassword,
            newPassword_confirmation: passwords.confirmPassword, // Ajout de ce champ
        };
    
        try {
            setLoading(true);
            setMessage(null);
            const response = await api.post('/update-user-password', userData);
    
            if (response.status === 200) {
                setMessage('Mot de passe mis à jour avec succès.');
            } else {
                setMessage('Une erreur est survenue.');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Erreur interne du serveur.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card sx={{ padding:"40px 20px", boxShadow: 1 }}>
            {message && (
                <Alert severity={message.includes('succès') ? 'success' : 'error'} sx={{ mb: 2 }}>
                    {message}
                </Alert>
            )}
        <form onSubmit={handleSave}>
            <TextField
                label="Adresse email"
                name="email"
                type="email"
                value=''
                fullWidth
                margin="normal"
                disabled
                sx={{ display: 'none' }} // Cache le champ visuellement
                autoComplete="username" // Indique que c'est le champ d'identifiant
            />
            <TextField
                label="Mot de passe actuel"
                name="currentPassword"
                type={showPassword ? 'text' : 'password'}
                value={passwords.currentPassword}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.currentPassword}
                helperText={errors.currentPassword}
                autoComplete="current-password" // Indique le champ actuel du mot de passe
                slotProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Nouveau mot de passe"
                name="newPassword"
                type={showPassword ? 'text' : 'password'}
                value={passwords.newPassword}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.newPassword}
                helperText={errors.newPassword}
                autoComplete="new-password" // Associer au nouveau mot de passe
                slotProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                label="Confirmer le mot de passe"
                name="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                value={passwords.confirmPassword}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                autoComplete="new-password" // Indique un nouveau mot de passe
                slotProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                variant="contained"
                onClick={handleSave}
                disabled={loading}
                sx={{ mt: 2 }}
                fullWidth
            >
                {loading ? <CircularProgress size={24} /> : 'Sauvegarder le mot de passe'}
            </Button>
            </form>
        </Card>
    );
}
