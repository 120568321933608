const en = {
  login: {
    welcome: "Sign in",
    emailAddress: "Email Address",
    password: "Password",
    rememberMe: "Remember me",
    signIn: "Sign In",
    forgotPassword: "Forgot password ?",
    dontHaveAccount: "Sign up",
    invalidEmail: "Invalid email address",
    validationError: "Please enter valid information.",
    loginSuccess: "Login successful!",
    loginError: "An error occurred. Please try again.",
    resendVerification: "Click here to resend verification email!",
    resendVerificationPrompt: "Your email address has not been confirmed yet!",
    verificationLimitReached: "You have reached the maximum verification attempts. Contact customer support.",
    loading: "Loading..."
  },
  register: {
    title: "Register",
    firstName: "First Name",
    lastName: "Last Name",
    gender: "Gender",
    female: "Female",
    male: "Male",
    other: "Other",
    birthDate: "Date of Birth",
    emailAddress: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password",
    acceptTerms: "I agree to the ",
    termsLink:" Terms and Conditions"
,    required: "* Required",
    submit: "Register",
    alreadyHaveAccount: "Already have an account ? Sign in",
    passwordMismatch: "Passwords do not match.",
    passwordError: "Password must have at least 8 characters, one uppercase, one lowercase, and one digit.",
    formError: "Please check the form for errors.",
    successMessage: "Registration successful! A confirmation email has been sent.",
    unexpectedError: "An unexpected error occurred.",
    serverError:"An unexpected error occurred"
  },
  forgetPassword: {
    title: "Forgot Password",
    emailLabel: "Email Address",
    sendButton: "Send",
    successMessage: "A reset link has been sent to your email.",
    errorSending: "Error while sending the reset link.",
    generalError: "An error occurred. Please try again.",
    loginLink: "Log In",
    registerLink: "Don't have an account? Sign up",
  },
  new_password : {
      title: "Reset Your Password",
      emailHiddenLabel: "Email Address",
      passwordLabel: "New Password",
      confirmPasswordLabel: "Confirm Password",
      passwordError: "Password must be at least 8 characters long, with one uppercase letter, one lowercase letter, and one number.",
      confirmPasswordError: "Passwords do not match.",
      resetButton: "Reset Password",
      invalidLink: "Invalid or incomplete link.",
      resetSuccess: "Your password has been successfully reset. You will be redirected to the login page in 5 seconds.",
      resetError: "An error occurred while resetting your password. Please try again.",
      loginLink: "Log In",
      signupLink: "Don't have an account? Sign up"
    
  },
  verifyEmail: {
    loading: "Verification in progress...",
    successMessage: "Your email has been successfully verified. You will be redirected to the login page.",
    errorMessage: "An error occurred during verification.",
    tokenMissing: "The token is missing from the URL.",
    expiredMessage: "The token has expired. Please request a new verification link.",
    resendButton: "Request a new link",
},
  choiceType: {
    title: "select the type of account you want to create",
    parentTitle: "parent account",
    parentDescription: "Create a parent account to register your children for activities and events.",
    parentButton: "choose parent",
    organizerTitle: "organizer account",
    organizerDescription: "Create an organizer account to offer and manage activities and events for children.",
    organizerButton: "choose organizer"
  },
  common: {
    loading: "Loading...",
    error: "An error occurred. Please try again.",
    submit: "Submit",
    cancel: "Cancel",
    save: "Save"
  }
};

export default en;