import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../utils/api';
import { CircularProgress, Box, Typography, Alert } from '@mui/material';

const ConfirmEmailChange = ({ isAuthenticated }) => {
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const confirmEmail = async () => {
            const token = searchParams.get('token');

            if (!token) {
                setStatus('error');
                setMessage("Le token de confirmation est manquant.");
                return;
            }

            try {
                const route = isAuthenticated
                    ? '/confirmed-user-email-online'
                    : 'auth/confirmed-user-email-offline';

                const response = await api.post(route, { token });

                if (response.data.success) {
                    setStatus('success');
                    setMessage(response.data.message || "Votre email a été confirmé avec succès.");
                    
                    // Si connecté, rediriger vers un tableau de bord
                    if (isAuthenticated) {
                        setTimeout(() => {
                            navigate('/parent/dashboard');
                        }, 2000);
                    }else{
                        setTimeout(() => {
                            navigate('/login');
                        }, 2000);
                    }
                } else {
                    throw new Error(response.data.message || "Erreur de confirmation.");
                }
            } catch (error) {
                setStatus('error');
                setMessage(
                    error.response?.data?.message ||
                        "Une erreur s'est produite lors de la confirmation de l'email."
                );
            }
        };

        confirmEmail();
    }, [isAuthenticated, navigate, searchParams]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
            }}
        >
            {status === 'loading' && <CircularProgress />}
            {status === 'success' && <Alert severity="success">{message}</Alert>}
            {status === 'error' && <Alert severity="error">{message}</Alert>}
            {status === 'success' && isAuthenticated && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Vous serez redirigé vers votre tableau de bord dans un instant...
                </Typography>
            )}
        </Box>
    );
};

export default ConfirmEmailChange;