const nl = {
  login: {
    welcome: "Inloggen",
    emailAddress: "E-mailadres",
    password: "Wachtwoord",
    rememberMe: "Onthoud mij",
    signIn: "Aanmelden",
    forgotPassword: "Wachtwoord vergeten?",
    dontHaveAccount: "Registreer je",
    invalidEmail: "Ongeldig e-mailadres",
    validationError: "Voer geldige informatie in.",
    loginSuccess: "Succesvol ingelogd!",
    loginError: "Er is een fout opgetreden. Probeer het opnieuw.",
    resendVerification: "Klik hier om de verificatie-e-mail opnieuw te verzenden!",
    resendVerificationPrompt: "Uw e-mailadres is nog niet bevestigd!",
    verificationLimitReached: "U heeft het maximum aantal verificatiepogingen bereikt. Neem contact op met de klantenservice.",
    loading: "Laden..."
  },
  register: {
    title: "Registreer",
    firstName: "Voornaam",
    lastName: "Achternaam",
    gender: "Geslacht",
    female: "Vrouw",
    male: "Man",
    other: "Ander",
    birthDate: "Geboortedatum",
    emailAddress: "E-mailadres",
    password: "Wachtwoord",
    confirmPassword: "Bevestig wachtwoord",
    acceptTerms: "Ik ga akkoord met",
    termsLink: "de Algemene Voorwaarden",
    required: "* Verplicht",
    submit: "Registreren",
    alreadyHaveAccount: "Heb je al een account ? Inloggen",
    passwordMismatch: "Wachtwoorden komen niet overeen.",
    passwordError: "Het wachtwoord moet minstens 8 tekens bevatten, één hoofdletter, één kleine letter en één cijfer.",
    formError: "Controleer het formulier op fouten.",
    successMessage: "Registratie gelukt! Een bevestigingsmail is verzonden.",
    unexpectedError: "Er is een onverwachte fout opgetreden."
  },
  forgetPassword: {
    title: "Wachtwoord vergeten",
    emailLabel: "E-mailadres",
    sendButton: "Verzenden",
    successMessage: "Er is een resetlink naar je e-mailadres gestuurd.",
    errorSending: "Fout bij het verzenden van de resetlink.",
    generalError: "Er is een fout opgetreden. Probeer het opnieuw.",
    loginLink: "Inloggen",
    registerLink: "Heb je geen account? Meld je aan",
  },
  new_password : {
    title: "Stel uw wachtwoord opnieuw in",
    emailHiddenLabel: "E-mailadres",
    passwordLabel: "Nieuw wachtwoord",
    confirmPasswordLabel: "Bevestig wachtwoord",
    passwordError: "Het wachtwoord moet minstens 8 tekens lang zijn, met één hoofdletter, één kleine letter en één cijfer.",
    confirmPasswordError: "Wachtwoorden komen niet overeen.",
    resetButton: "Wachtwoord opnieuw instellen",
    invalidLink: "Ongeldige of onvolledige link.",
    resetSuccess: "Uw wachtwoord is succesvol opnieuw ingesteld. U wordt binnen 5 seconden doorgestuurd naar de inlogpagina.",
    resetError: "Er is een fout opgetreden bij het opnieuw instellen van uw wachtwoord. Probeer het opnieuw.",
    loginLink: "Inloggen",
    signupLink: "Heb je nog geen account? Meld je aan"
  
},
  verifyEmail: {
      loading: "Bezig met verificatie...",
      successMessage: "Uw e-mail is succesvol geverifieerd. U wordt doorgestuurd naar de inlogpagina.",
      errorMessage: "Er is een fout opgetreden tijdens de verificatie.",
      tokenMissing: "De token ontbreekt in de URL.",
      expiredMessage: "De token is verlopen. Vraag een nieuwe verificatielink aan.",
      resendButton: "Vraag een nieuwe link aan",
  },
  choiceType: {
      title: "selecteer het type account dat u wilt aanmaken",
      parentTitle: "ouderaccount",
      parentDescription: "Maak een ouderaccount aan om je kinderen in te schrijven voor activiteiten en evenementen.",
      parentButton: "ouder kiezen",
      organizerTitle: "organisatoraccount",
      organizerDescription: "Maak een organisatoraccount aan om activiteiten en evenementen voor kinderen aan te bieden en te beheren.",
      organizerButton: "organisator kiezen"
    },
  common: {
    loading: "Laden...",
    error: "Er is een fout opgetreden. Probeer het opnieuw.",
    submit: "Verzenden",
    cancel: "Annuleren",
    save: "Opslaan"
  }
};

export default nl;