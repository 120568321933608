// utils/passwordValidation.js

/**
 * Valide les critères d'un mot de passe.
 * @param {string} password - Le mot de passe à valider.
 * @returns {boolean} - Retourne true si le mot de passe est valide, sinon false.
 */
export const validatePassword = (password) => {
    const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+{}:"<>?]{8,128}$/;
    return passwordCriteria.test(password);
};