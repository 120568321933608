import { constants } from "../auth/_constants/constants";
import api from "../utils/api";


export const getUser = () => {
    return async (dispatch) => {
            try {
                const response = await api.get('/user-profile-parent'); 

                    if (response.data.status) {
                        dispatch({
                            type: constants.GET_USER,
                            payload: response.data
                        })
                    } else {
                        dispatch({
                            type: constants.GET_USER_ERR,
                            payload: response.data
                        })
                    }
                
            } catch (err) {
                dispatch({
                    type: constants.GET_USER_ERR,
                    payload: err.message
                })
            }
    }
}


export const getUserAdress = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/get-user-adress');
            if (response.status === 200 && response.data.adress) {
                dispatch({
                    type: constants.UPDATE_USER_ADRESS,
                    payload: {
                        adress: response.data.adress, // On passe uniquement l'adresse
                    },
                });
            } else {
                dispatch({
                    type: constants.GET_USER_ERR,
                    payload: response.data.message || 'Erreur inconnue',
                });
            }
        } catch (err) {
            dispatch({
                type: constants.GET_USER_ERR,
                payload: err.message,
            });
        }
    };
};