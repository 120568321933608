import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Box,
    Link,
    Grid2,
    Typography,
    Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Hook pour les traductions
import api from '../utils/api';
import { validatePassword } from '../utils/Regex';
import LanguageSwitcher from '../lang/components/LanguageSwitcher';

const theme = createTheme();

export default function NewPassword() {
    const { t } = useTranslation(); // Initialisation pour les traductions
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('error');

    useEffect(() => {
        if (!token || !email) {
            setAlertMessage(t('new_password.invalidLink'));
            setAlertSeverity('error');
        }
    }, [token, email, t]);

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        setAlertMessage('');

        if (!validatePassword(value)) {
            setPasswordError(t('new_password.passwordError'));
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value);
        setAlertMessage('');

        if (password !== value) {
            setConfirmPasswordError(t('new_password.confirmPasswordError'));
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!token || !email) {
            setAlertMessage(t('new_password.invalidLink'));
            setAlertSeverity('error');
            return;
        }

        if (!validatePassword(password) || password !== confirmPassword) {
            setAlertMessage(t('new_password.resetError'));
            setAlertSeverity('error');
            return;
        }

        try {
            const response = await api.post('auth/reset-password', {
                token,
                email,
                password,
                password_confirmation: confirmPassword,
            });

            if (response.data?.success) {
                setAlertMessage(t('new_password.resetSuccess'));
                setAlertSeverity('success');

                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            } else {
                setAlertMessage(response.data?.message || t('new_password.resetError'));
                setAlertSeverity('error');
            }
        } catch (error) {
            setAlertMessage(
                error?.response?.data?.message || t('new_password.resetError')
            );
            setAlertSeverity('error');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid2 container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid2
                    xs={12}
                    md={4}
                    elevation={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mx: 'auto',
                        maxWidth: '500px',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            p: 4,
                        }}
                    >
                        {alertMessage && (
                            <Alert  severity={alertSeverity} sx={{ mb: 1, width: '100%' }}>
                                {alertMessage}
                            </Alert>
                        )}
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t('new_password.title')}
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('new_password.passwordLabel')}
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                size="small"
                                value={password}
                                onChange={handlePasswordChange}
                                error={!!passwordError}
                                helperText={passwordError}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label={t('new_password.confirmPasswordLabel')}
                                type="password"
                                id="confirm-password"
                                autoComplete="new-password"
                                size="small"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                error={!!confirmPasswordError}
                                helperText={confirmPasswordError}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 1.2 }}
                                disabled={
                                    !!passwordError ||
                                    !!confirmPasswordError ||
                                    !password ||
                                    !confirmPassword ||
                                    !token ||
                                    !email
                                }
                            >
                                {t('new_password.resetButton')}
                            </Button>
                            <Grid2 container sx={{ justifyContent: 'space-between' }}>
                                <Grid2>
                                    <Link href="/login" variant="body2" sx={{ textDecoration: 'none' }}>
                                        {t('new_password.loginLink')}
                                    </Link>
                                </Grid2>
                                <Grid2>
                                    <Link href="/register" variant="body2" sx={{ textDecoration: 'none' }}>
                                        {t('new_password.signupLink')}
                                    </Link>
                                </Grid2>
                            </Grid2>
                        </Box>
                        <Box sx={{ mt: 5, mb: 2 }}>
                            <LanguageSwitcher />
                            <Typography variant="body2" color="text.secondary" align="center">
                                {'Copyright © '}
                                <Link color="inherit" href="https://i-kiddy.com/">
                                    i-Kiddy
                                </Link>{' '}
                                {new Date().getFullYear()}.
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </ThemeProvider>
    );
}