import React from 'react';
import { Avatar, CssBaseline, Grid2, Box, Typography, Link } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import RegistrationTabs from './RegistrationTabs';
import LanguageSwitcher from '../lang/components/LanguageSwitcher';
import Copyright from '../components/functions/Functions';
import { useTranslation } from 'react-i18next';

export default function RegistrationContainer() {
  const { t } = useTranslation(); // Utilisation des traductions

  return (
    // Le composant hérite du thème global (défini dans index.js)
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <Grid2 container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid2
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mx: 'auto',
            maxWidth: '500px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              p: 0,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'transparent', width: 56, height: 56 }}>
              <img
                src="https://www.i-kiddy.com/images/i-kiddy-logo.png"
                alt="Logo i-Kiddy"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              Créez votre compte
            </Typography>

            <RegistrationTabs />
          </Box>
          <Grid2 container justifyContent="flex-end">
            <Grid2>
              <Link href="/login" variant="body2" sx={{ textDecoration: 'none' }}>
                {t('register.alreadyHaveAccount')}
              </Link>
            </Grid2>
          </Grid2>
          <LanguageSwitcher />
          <Box sx={{ mt: 5, mb: 2 }}>
            <Copyright />
          </Box>
        </Grid2>
      </Grid2>
    </LocalizationProvider>
  );
}