import React, { useState, useEffect } from 'react';
import { 
  Box,
  Button,
  TextField,
  Alert,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import api from '../../../../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { constants } from '../../../../../auth/_constants/constants';

const ParticipationOptionsForm = ({ onSubmit }) => {
  // Récupération de l'activité depuis Redux
  const reduxActivity = useSelector((state) => state.activities.activity);
  const dispatch = useDispatch();

  // États pour les champs obligatoires
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const [childPrice, setChildPrice] = useState('');
  const [minRegistrations, setMinRegistrations] = useState('');
  const [maxRegistrations, setMaxRegistrations] = useState('');
  
  // États pour le genre : allowedFemale et allowedMale
  const [allowedFemale, setAllowedFemale] = useState(false);
  const [allowedMale, setAllowedMale] = useState(false);
  
  // États pour l'accompagnement parental
  const [parentAccompanied, setParentAccompanied] = useState(false);
  const [nbrKidsPerParent, setNbrKidsPerParent] = useState('');
  const [parentPrice, setParentPrice] = useState('');
  
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  // Initialisation des valeurs par défaut depuis reduxActivity
  useEffect(() => {
    if (reduxActivity) {
      setMinAge(reduxActivity.minAge || '');
      setMaxAge(reduxActivity.maxAge || '');
      setChildPrice(reduxActivity.childPrice || '');
      setMinRegistrations(reduxActivity.minRegistrations || '');
      setMaxRegistrations(reduxActivity.maxRegistrations || '');
      // Convertir les valeurs pour allowedFemale et allowedMale en booléens
      setAllowedFemale(
        reduxActivity.allowedFemale === "1" ||
        reduxActivity.allowedFemale === 1 ||
        reduxActivity.allowedFemale === true
      );
      setAllowedMale(
        reduxActivity.allowedMale === "1" ||
        reduxActivity.allowedMale === 1 ||
        reduxActivity.allowedMale === true
      );
      setParentAccompanied(!!reduxActivity.parentAccompanied);
      setNbrKidsPerParent(reduxActivity.nbrKidsPerParent || '');
      setParentPrice(reduxActivity.parentPrice || '');
    }
  }, [reduxActivity]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMsg('');

    // Validation des champs obligatoires et du genre
    if (!minAge || !maxAge || !childPrice || !minRegistrations || !maxRegistrations || (!allowedFemale && !allowedMale)) {
      setError('Veuillez remplir tous les champs obligatoires et sélectionner au moins un genre.');
      return;
    }
    if (parentAccompanied && (!nbrKidsPerParent || !parentPrice)) {
      setError('Veuillez remplir les informations pour l’accompagnement parental.');
      return;
    }

    const formData = {
      id: reduxActivity?.id,
      organizer_id: reduxActivity?.organizer_id,
      minAge: Number(minAge),
      maxAge: Number(maxAge),
      childPrice: Number(childPrice),
      minRegistrations: Number(minRegistrations),
      maxRegistrations: Number(maxRegistrations),
      allowedFemale,
      allowedMale,
      parentAccompanied,
      nbrKidsPerParent: parentAccompanied ? Number(nbrKidsPerParent) : null,
      parentPrice: parentAccompanied ? Number(parentPrice) : null,
    };

    try {
      const response = await api.post("organizer/update-genderEndNumber", formData);
      setSuccessMsg(response.data.message);
      dispatch({
        type: constants.GET_ACTIVITY,
        payload: response.data.activity,
      });
      if (onSubmit) {
        onSubmit(formData);
      }
    } catch (err) {
      setError("Erreur lors de la mise à jour des options de participation.");
      console.error(err);
    }
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ p: 2, maxWidth: 600, mx: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      {successMsg && <Alert severity="success">{successMsg}</Alert>}
      
      <Typography variant="h6" component="h2">Options de participation</Typography>
      
      {/* Choix du genre */}
      <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">Genre des participants</FormLabel>
        <FormControlLabel 
          control={
            <Checkbox 
              checked={allowedFemale} 
              onChange={(e) => setAllowedFemale(e.target.checked)} 
            />
          } 
          label="Filles" 
        />
        <FormControlLabel 
          control={
            <Checkbox 
              checked={allowedMale} 
              onChange={(e) => setAllowedMale(e.target.checked)} 
            />
          } 
          label="Garçons" 
        />
      </FormControl>

      {/* Âge minimum, âge maximum et prix par enfant */}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Âge minimum"
            value={minAge}
            onChange={(e) => setMinAge(e.target.value)}
            type="number"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Âge maximum"
            value={maxAge}
            onChange={(e) => setMaxAge(e.target.value)}
            type="number"
            fullWidth
            size="small"
            InputProps={{ style: { textAlign: 'right' } }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Prix par enfant"
            value={childPrice}
            onChange={(e) => setChildPrice(e.target.value)}
            type="number"
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>

      {/* Nombre minimum et maximum d'inscriptions */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Nombre min d'inscriptions"
            value={minRegistrations}
            onChange={(e) => setMinRegistrations(e.target.value)}
            type="number"
            fullWidth
            size="small"
            InputProps={{ style: { textAlign: 'right' } }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Nombre max d'inscriptions"
            value={maxRegistrations}
            onChange={(e) => setMaxRegistrations(e.target.value)}
            type="number"
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>

      {/* Option d'accompagnement parental */}
      <FormControlLabel
        control={
          <Checkbox
            checked={parentAccompanied}
            onChange={(e) => setParentAccompanied(e.target.checked)}
          />
        }
        label="L'activité est accompagnée par un parent"
      />

      {parentAccompanied && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Nombre d'enfants par parent"
              value={nbrKidsPerParent}
              onChange={(e) => setNbrKidsPerParent(e.target.value)}
              type="number"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Prix du parent"
              value={parentPrice}
              onChange={(e) => setParentPrice(e.target.value)}
              type="number"
              fullWidth
              size="small"
              InputProps={{ style: { textAlign: 'right' } }}
            />
          </Grid>
        </Grid>
      )}

      <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
        Enregistrer les options
      </Button>
    </Box>
  );
};

export default ParticipationOptionsForm;