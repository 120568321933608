// index.jsx
import React, { useState, createContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/theme.css'; // Votre fichier CSS personnalisé (optionnel)
import App from './App';
import rootReducer from './reduxReducer';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import '../src/lang/i18n';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';

const store = configureStore({
  reducer: rootReducer,
});

export { store };

// Créer un contexte pour le mode de thème
export const ThemeModeContext = createContext({
  mode: 'dark',
  toggleTheme: () => {},
});

const AppWrapper = () => {
  // Lire le mode enregistré dans le localStorage ou utiliser "dark" par défaut
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem("themeMode");
    return savedMode ? savedMode : "dark";
  });

  // Mettre à jour l'attribut "data-theme" sur le document pour que le CSS s'applique
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', mode);
  }, [mode]);

  // Fonction pour basculer le thème et enregistrer dans le localStorage
  const toggleTheme = () => {
    setMode((prevMode) => {
      const newMode = prevMode === "dark" ? "light" : "dark";
      localStorage.setItem("themeMode", newMode);
      return newMode;
    });
  };

  // Définir le thème Material UI en fonction du mode
  const theme = createTheme({
    palette: {
      mode, // Dynamique ("dark" ou "light")
      primary: {
        main: mode === 'dark' ? blueGrey[100] : blueGrey[500],
      },
      secondary: {
        main: mode === 'dark' ? blueGrey[100] : blueGrey[500],
      },
      background: {
        default: mode === 'dark' ? blueGrey[900] : '#fff',
        paper: mode === 'dark' ? blueGrey[900] : '#fff',
      },
      text: {
        primary: mode === 'dark' ? '#ECEFF1' : '#000',
        secondary: mode === 'dark' ? '#B0BEC5' : '#555',
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 2,
            padding: '8px 16px',
            // En mode sombre, les boutons sont orange, texte blanc, sans ombre
            backgroundColor: mode === 'dark' ? orange[500] : orange[500],
            color: mode === 'dark' ? '#fff' : '#fff',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: mode === 'dark' ? orange[700] : orange[700],
              boxShadow: 'none',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeModeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* Applique les styles globaux */}
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);