import React from "react";
import { useParams } from "react-router-dom";
import CreatePortalForm from "../page/CreatePortalForm";
import { Box } from "@mui/material";
import ParametersTabs from "../../components/ParametersTabs";
import OrganizationCard from "./components/OrganizationCard";
import ActivityManagement from "./components/ActivityManagement";

// Composant 404
const NotFoundComponent = () => <div>404 - Composant non trouvé---</div>;

const ContentSwitcher = () => {
  const { section, tab2 } = useParams();

  const renderComponent = () => {
    switch (section) {
      case "o":
        // Pour la section "o", on vérifie la valeur de tab2
        if (!tab2) return <NotFoundComponent />;
        switch (tab2) {
          case "info-organizer":
            return <OrganizationCard />;
          case "activities":
            return <ActivityManagement/>;
          default:
            return <NotFoundComponent />;
        }
      case "parameters":
        return <ParametersTabs />;
      case "dashboard":
        return <div>Hello</div>;
      case "new-organization":
        return <CreatePortalForm />;
      default:
        return <NotFoundComponent />;
    }
  };

  return (
    <Box sx={{ justifyContent: "center" }}>
      {renderComponent()}
    </Box>
  );
};

export default ContentSwitcher;