import React from 'react';
import { useParams } from 'react-router-dom';
import PrivatePage from '../PrivatePage';
import CreatePortalForm from '../page/CreatePortalForm';
import ParametersTabs from '../../components/ParametersTabs';
import ChildrenInfo from '../parents/components/ChildrenInfo';
import { Box } from '@mui/material';

// Composant 404
const NotFoundComponent = () => <div>404 - Composant non trouvé--</div>;

function ContentSwitcher() {
  const { section, tab } = useParams(); // Récupère les paramètres dynamiques dans l'URL

  // Mapping des sections aux composants correspondants
    const renderComponent = () => {
    switch (section) {
        case 'dashboard':
            return <PrivatePage />;
        case 'createPortalForm':
            return <CreatePortalForm />;
        case 'children':
            return <ChildrenInfo />; // Passe le paramètre `tab` au composant ParametersTabs
        case 'parameters':
            return <ParametersTabs defaultTab={tab} />; // Passe le paramètre `tab` au composant ParametersTabs
        default:
            return <NotFoundComponent />;
    }
    };

  return <Box sx={{
    // width: "100%",
    // height: "100vh", // Hauteur pour centrer verticalement
    // display: "flex",
    // justifyContent: "center", // Centre horizontalement
  }}>
    {renderComponent()}
  </Box>
}

export default ContentSwitcher;