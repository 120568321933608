import React, { useState } from 'react';
import {
    Button,
    Box,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    CircularProgress,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import api from '../../../utils/api';
import ChildrenList from './ChildrenList';

export default function ChildrenInfo() {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        firstname: '', // Par défaut une chaîne vide au lieu de null
        lastname: '', // Par défaut une chaîne vide au lieu de null
        birthday: '', // Par défaut une chaîne vide au lieu de null
        gender: 'male',
        nationalId: '', // Par défaut une chaîne vide au lieu de null
    });
    const [formError, setFormError] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        resetForm();
        setIsLoading(false);
    };

    const resetForm = () => {
        setFormData({
            id: '',
            firstname: '',
            lastname: '',
            birthday: '',
            gender: 'male',
            nationalId: '',
        });
        setFormError({});
        setSuccessMessage('');
    };

    const validateForm = () => {
        const errors = {};

        if (!formData.firstname.trim()) {
            errors.firstname = 'Le prénom est obligatoire.';
        }
        if (!formData.lastname.trim()) {
            errors.lastname = 'Le nom est obligatoire.';
        }
        if (!formData.birthday) {
            errors.birthday = 'La date de naissance est obligatoire.';
        }
        if (!formData.gender) {
            errors.gender = 'Le genre est obligatoire.';
        }

        setFormError(errors);
        return Object.keys(errors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value || '' }); // Définit une chaîne vide si la valeur est null

        if (formError[name]) {
            setFormError({ ...formError, [name]: undefined });
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        setFormError('');
        setIsLoading(true);

        const dataToSend = {
            firstname: formData.firstname,
            lastname: formData.lastname,
            birthday: formData.birthday,
            gender: formData.gender,
            national_id: formData.nationalId || null, // Permet de définir `null` explicitement pour une valeur vide
        };

        try {
            let response;
            if (formData.id) {
                const newDataToSend = { ...dataToSend, child_id: formData.id };
                // Mise à jour d'un enfant existant
                response = await api.post('parent/updateChild', newDataToSend);

                // Injection directe des données modifiées dans le reducer
                const updatedChild = response.data.child;
                dispatch({
                    type: 'UPDATE_CHILD',
                    payload: updatedChild,
                });
            } else {
                // Ajout d'un nouvel enfant
                response = await api.post('parent/newKid', dataToSend);

                // Ajouter le nouvel enfant dans le reducer
                const newChild = response.data.child;
                dispatch({
                    type: 'ADD_CHILD',
                    payload: newChild,
                });
            }

            setSuccessMessage(response.data.message);
            handleClose();
        } catch (error) {
            setFormError({
                global:
                    error.response?.data?.message || 'Erreur lors de l’enregistrement des données.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (child) => {
        setFormData({
            id: child.id,
            firstname: child.firstname || '', // Définit une chaîne vide si la valeur est null
            lastname: child.lastname || '', // Définit une chaîne vide si la valeur est null
            birthday: child.birthday || '', // Définit une chaîne vide si la valeur est null
            gender: child.gender || 'male', // Valeur par défaut
            nationalId: child.national_id || '', // Définit une chaîne vide si la valeur est null
        });
        setOpen(true);
    };

    
    return (
        <Box>
            {/* En-tête avec le bouton d'ajout */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography variant="h6">Gestion des enfants</Typography>
                <Button variant="contained" onClick={handleOpen}>
                    Ajouter un enfant
                </Button>
            </Box>

            {/* Liste des enfants */}
            <ChildrenList onEdit={handleEdit} />

            {/* Modal d'ajout/modification */}
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {formData.id ? 'Modifier un enfant' : 'Ajouter un enfant'}
                    <Button
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <DialogContent dividers>
                    {formError.global && <Alert severity="error" sx={{ mb: 2 }}>{formError.global}</Alert>}
                    {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <TextField
                            name="firstname"
                            label="Prénom"
                            fullWidth
                            value={formData.firstname}
                            onChange={handleInputChange}
                            error={!!formError.firstname}
                            helperText={formError.firstname}
                        />
                        <TextField
                            name="lastname"
                            label="Nom"
                            fullWidth
                            value={formData.lastname}
                            onChange={handleInputChange}
                            error={!!formError.lastname}
                            helperText={formError.lastname}
                        />
                    </Box>
                    <TextField
                        name="birthday"
                        label="Date de naissance"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={formData.birthday}
                        onChange={handleInputChange}
                        error={!!formError.birthday}
                        helperText={formError.birthday}
                        sx={{ mb: 2 }}
                    />
                    <FormControl sx={{ mb: 2 }} error={!!formError.gender}>
                        <FormLabel>Genre</FormLabel>
                        <RadioGroup
                            row
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="male" control={<Radio />} label="Masculin" />
                            <FormControlLabel value="female" control={<Radio />} label="Féminin" />
                            <FormControlLabel value="other" control={<Radio />} label="Autre" />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        name="nationalId"
                        label="Numéro national"
                        fullWidth
                        value={formData.nationalId}
                        onChange={handleInputChange}
                        sx={{ mb: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={isLoading}>
                        Annuler
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Enregistrer'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}