import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ isAuthenticated, restricted, children }) => {
    if (isAuthenticated && restricted) {
        const user = JSON.parse(localStorage.getItem('user'));
        const role = user?.role;

        // Redirige vers la route appropriée selon le rôle
        if (role === 'parent') return <Navigate to="/parent/dashboard" replace />;
        if (role === 'organizer') return <Navigate to="/organizer/dashboard" replace />;
        if (role === 'admin') return <Navigate to="/admin/dashboard" replace />;
    }

    return children;
};

export default PublicRoute;