import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Alert,
  MenuItem,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import SearchAddress from "../../components/SearchAddress";
import api from "../../utils/api";
import { useDispatch } from "react-redux";
import { constants } from "../../auth/_constants/constants";
import { useNavigate } from "react-router-dom";

const CreateOrganizerForm = ({ existingData = null, title, onClose }) => {

  // États des champs
  const [organizationName, setOrganizationName] = useState(existingData?.organizationName || "");
  const [bankAccount, setBankAccount] = useState(existingData?.bankAccount || "");
  const [taxId, setTaxId] = useState(existingData?.taxId || ""); // Utilisation de taxId et non tax_id
  const [companyNumber, setCompanyNumber] = useState(existingData?.companyNumber || "");
  const [email, setEmail] = useState(existingData?.email || "");
  const [phoneNumber, setPhone] = useState(existingData?.phoneNumber || "");
  const [website, setWebsite] = useState(existingData?.website || "");
  const [category, setCategory] = useState(existingData?.category || "");
  const [description, setDescription] = useState(existingData?.description || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formModified, setFormModified] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Adresse initiale : si existingData n'est pas défini, on initialise avec des valeurs par défaut
  const [address, setAddress] = useState(
    existingData?.address || {
      streetNumber: "",
      streetName: "",
      postalCode: "",
      city: "",
      country: "France",
      latitude: null,
      longitude: null,
    }
  );

  useEffect(() => {
    setFormModified(false);
  }, [existingData]);

  // Sélection d'adresse via SearchAddress
  const handleAddressSelect = (selectedAddress) => {
    setAddress(selectedAddress.details);
    setFormModified(true);
  };

  // Gestion des modifications d'un champ
  const handleChange = (setter) => (e) => {
    setter(e.target.value);
    setFormModified(true);
  };

  // Soumission du formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setErrors({}); // Réinitialisation des erreurs avant soumission
    setIsSubmitting(true);

    // Aplatir l'objet address pour transmettre les clés attendues par le back-end
    const formData = {
      id : existingData?.id,
      organizationName,
      bankAccount,
      taxId,
      companyNumber,
      email,
      phoneNumber,
      website,
      category,
      description,
      ...address, // Décompose l'objet adresse en streetName, streetNumber, postalCode, city, country, latitude, longitude
    };

    try {
      // Sélection de l'endpoint en fonction de s'il s'agit d'une mise à jour ou d'une création
      const endpoint = existingData
        ? "organizer/update-organizer"
        : "organizer/new-organizer";
      const response = await api.post(endpoint, formData);

      setMessage(
        existingData
          ? "Organisation mise à jour avec succès !"
          : "Organisation créée avec succès !"
      );

      if(existingData){
        dispatch({
          type: constants.UPDATE_ORGANIZER,
          payload: response.data,
        });
        onClose()
      }else{
        dispatch({
          type: constants.NEW_ORGANIZER,
          payload: response.data,
        });
        navigate(response.data.redirection); // Met à jour l'URL

      }
      
      setFormModified(false);
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        setMessage("Une erreur est survenue lors de l'enregistrement.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ maxWidth: "800px", margin: "auto", padding: "20px" }}>
      <Typography variant="h5" gutterBottom>
        {title ? title : (existingData ? "Modifier l'Organisation" : "Créer une Organisation")}
      </Typography>

      {message && (
        <Alert severity={message.includes("succès") ? "success" : "error"} sx={{ mb: 2 }}>
          {message}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Nom de l'organisation"
          value={organizationName}
          onChange={handleChange(setOrganizationName)}
          margin="normal"
          required
          error={!!errors.organizationName}
          helperText={errors.organizationName?.[0]}
        />

        {/* Détails administratifs */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Compte bancaire"
              value={bankAccount}
              onChange={handleChange(setBankAccount)}
              margin="normal"
              required
              error={!!errors.bankAccount}
              helperText={errors.bankAccount?.[0]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Taxe ID"
              value={taxId}
              onChange={handleChange(setTaxId)}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="ID fiscal (uniquement si votre organisation est assujettie à la TVA)">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={!!errors.taxId}
              helperText={errors.taxId?.[0]}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Numéro d'entreprise"
              value={companyNumber}
              onChange={handleChange(setCompanyNumber)}
              margin="normal"
              required
              error={!!errors.companyNumber}
              helperText={errors.companyNumber?.[0]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label="Catégorie"
              value={category}
              onChange={handleChange(setCategory)}
              margin="normal"
              required
              error={!!errors.category}
              helperText={errors.category?.[0]}
            >
              <MenuItem value="school">École</MenuItem>
              <MenuItem value="sports_club">Club Sportif</MenuItem>
              <MenuItem value="leisure_center">Centre de Loisirs</MenuItem>
              <MenuItem value="company">Entreprise</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        {/* Contact */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={handleChange(setEmail)}
              margin="normal"
              type="email"
              required
              error={!!errors.email}
              helperText={errors.email?.[0]}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Téléphone"
              value={phoneNumber}
              onChange={handleChange(setPhone)}
              margin="normal"
              required
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.[0]}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Site Web"
              value={website}
              onChange={handleChange(setWebsite)}
              margin="normal"
              error={!!errors.website}
              helperText={errors.website?.[0]}
            />
          </Grid>
        </Grid>

        {/* Adresse */}
        <SearchAddress defaultAddress={existingData?.address} handleAddressSelect={handleAddressSelect} />
        {errors.address && <Alert severity="error" sx={{ mt: 1 }}>{errors.address[0]}</Alert>}

        <TextField
          fullWidth
          label="Description"
          value={description}
          onChange={handleChange(setDescription)}
          margin="normal"
          multiline
          rows={4}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!formModified || isSubmitting}
          sx={{ mt: 2 }}
        >
          {isSubmitting ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : existingData ? (
            "Mettre à jour"
          ) : (
            "Créer l'organisation"
          )}
        </Button>
      </form>
    </Box>
  );
};

export default CreateOrganizerForm;