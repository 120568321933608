import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, allowedRoles = [], userRole, children }) => {
    // Empêche la redirection tant que les valeurs ne sont pas prêtes
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const token = localStorage.getItem('access_token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (token && user) {
            setIsLoading(false); // Données prêtes
        } else {
            setIsLoading(false); // Données prêtes mais non authentifiées
        }
    }, []);

    if (isLoading) {
        return <div>Chargement...</div>; // Affiche une page de chargement pendant l'initialisation
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    // Vérification des rôles autorisés
    if (allowedRoles.length > 0 && userRole && !allowedRoles.includes(userRole)) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default PrivateRoute;