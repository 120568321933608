import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Slider,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Cropper from "react-easy-crop";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import api from "../utils/api";
import { useDispatch } from "react-redux";
import { constants } from "../auth/_constants/constants";
import { cropImage } from "../utils/cropImage";

const parseAspectRatio = (ratioStr) => {
  // Si aucun ratio n'est fourni, on retourne 1 (1/1)
  if (!ratioStr) return 1;
  const parts = ratioStr.split("/");
  if (parts.length !== 2) return 1;
  const numerator = parseFloat(parts[0]);
  const denominator = parseFloat(parts[1]);
  return denominator !== 0 ? numerator / denominator : 1;
};

export default function AvatarEditorModal({
  childId,
  id,
  open,
  onClose,
  typePathBD,
  currentAvatar,
  format, // par ex: "16/9"
}) {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [hasAvatar, setHasAvatar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  // Calcul du ratio de crop (aspect)
  const aspectRatio = parseAspectRatio(format) || 1; // Par défaut 1/1

  // Chemins API
  const pathBD = {
    child: "parent/update-avatar",
    user: "/update-user-avatar",
    logo: "organizer/update-logo",
    poster: "organizer/update-poster",
  };

  const pathBD_ = {
    child: "parent/delete-avatar",
    user: "/delete-user-avatar",
    logo: "organizer/delete-logo",
    poster: "organizer/delete-poster",
  };

  // Vérifier si un avatar existe au chargement
  useEffect(() => {
    setHasAvatar(currentAvatar !== null && currentAvatar !== "");
  }, [currentAvatar]);

  // Réinitialiser les états après la fermeture du modal
  useEffect(() => {
    if (!open) {
      setImageSrc(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setRotation(0);
      setCroppedAreaPixels(null);
      setErrorMessage("");
    }
  }, [open]);

  const handleFileChange = (file) => {
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage("Le fichier est trop volumineux (max 5 Mo).");
        return;
      }
      const validTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      if (!file.type || file.type === "0" || !validTypes.includes(file.type)) {
        setErrorMessage(
          "Type de fichier non valide. Seuls les fichiers JPEG, PNG, GIF et WEBP sont acceptés."
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
        setErrorMessage("");
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!imageSrc || !croppedAreaPixels) {
      console.error("Aucune image ou zone de crop définie !");
      return;
    }
    setIsUploading(true);
    try {
      const croppedImage = await cropImage(imageSrc, croppedAreaPixels, rotation);
      const formData = new FormData();
      formData.append("child_id", childId);
      formData.append("avatar", croppedImage);
      formData.append("id", id);
      const response = await api.post(pathBD[typePathBD], formData);
      if (typePathBD === "user") {
        dispatch({
          type: constants.UPDATE_USER_AVATAR,
          payload: { avatar: response.data.avatar },
        });
        const currentUser = JSON.parse(localStorage.getItem("user"));
        currentUser.avatar = response.data.avatar;
        localStorage.setItem("user", JSON.stringify(currentUser));
      } else if (typePathBD === "child") {
        dispatch({
          type: constants.UPDATE_CHILD_AVATAR,
          payload: { id: childId, avatar: response.data.avatar },
        });
      } else if (typePathBD === "logo") {
        dispatch({
          type: constants.UPDATE_LOGO,
          payload: { id, logo: response.data.logo },
        });
      }else if (typePathBD === "poster") {
        dispatch({
          type: constants.UPDATE_POSTER,
          payload: { id, poster: response.data.poster },
        });
      }
      setHasAvatar(true);
      setImageSrc(null);
      setSnackbarMessage("Avatar enregistré avec succès !");
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      setErrorMessage("Erreur lors de l'enregistrement. Veuillez réessayer.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await api.post(pathBD_[typePathBD], { child_id: childId, id });
      if (typePathBD === "user") {
        dispatch({
          type: constants.REMOVE_USER_AVATAR,
          payload: { avatar: null },
        });
        const currentUser = JSON.parse(localStorage.getItem("user"));
        currentUser.avatar = null;
        localStorage.setItem("user", JSON.stringify(currentUser));
      } else if (typePathBD === "child") {
        dispatch({
          type: constants.REMOVE_CHILD_AVATAR,
          payload: { id: childId },
        });
      } else if (typePathBD === "logo") {
        dispatch({
          type: constants.REMOVE_LOGO,
          payload: { id, logo: null },
        });
      }else if (typePathBD === "poster") {
        dispatch({
          type: constants.REMOVE_POSTER,
          payload: { id, poster: null },
        });
      }
      setHasAvatar(false);
      setImageSrc(null);
      setSnackbarMessage("Avatar supprimé avec succès !");
      setSnackbarOpen(true);
      onClose();
    } catch (err) {
      setErrorMessage("Erreur lors de la suppression. Veuillez réessayer.");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Modifier l'avatar</DialogTitle>
      <DialogContent dividers>
        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}
        {imageSrc ? (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: 400,
              backgroundColor: "#333",
            }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={aspectRatio} // Utilise le ratio calculé (par ex. 16/9 ou 1/1)
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropComplete={(area, pixels) => setCroppedAreaPixels(pixels)}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  handleFileChange(e.target.files[0]);
                }
              }}
            />
            <Button
              variant="outlined"
              onClick={() =>
                fileInputRef.current && fileInputRef.current.click()
              }
              startIcon={<CloudUploadIcon />}
            >
              Sélectionner une image
            </Button>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, gap: 2 }}>
          <IconButton
            onClick={() => setRotation((prev) => prev - 90)}
            aria-label="Rotation à gauche"
          >
            <RotateLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => setRotation((prev) => prev + 90)}
            aria-label="Rotation à droite"
          >
            <RotateRightIcon />
          </IconButton>
          <Slider
            value={zoom}
            onChange={(e, value) => setZoom(value)}
            min={1}
            max={3}
            step={0.1}
            sx={{ width: 150 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            onClick={handleDelete}
            disabled={!hasAvatar || isDeleting}
            color="error"
            sx={{ mr: "auto" }}
          >
            {isDeleting ? <CircularProgress size={20} /> : "Supprimer l'avatar"}
          </Button>
          <Box>
            <Button onClick={onClose} color="secondary" disabled={isUploading}>
              Annuler
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={!imageSrc || isUploading}
            >
              {isUploading ? <CircularProgress size={20} /> : "Enregistrer"}
            </Button>
          </Box>
        </Box>
      </DialogActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Dialog>
  );
}