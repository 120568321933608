// ActivityPeriodForm.jsx
import React, { useState, useEffect } from 'react';
import { 
  Box,
  Button,
  Alert,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import api from '../../../../../utils/api';
import { constants } from '../../../../../auth/_constants/constants';
import { extractTimeUTC } from '../../../../../functions/DateTimeDisplay';

dayjs.extend(utc);

// Fonction utilitaire : convertit une chaîne de temps (ex: "09:47:00") en objet Dayjs en UTC
const parseTime = (timeStr) => {
  if (!timeStr) return null;
  return dayjs.utc(`1970-01-01 ${timeStr}`);
};

export default function ActivityPeriodForm() {
  // Formats
  const displayFormatDate = "DD/MM/YYYY";
  const sendFormatDate = "YYYY-MM-DD"; // par exemple "2025-01-12"
  const displayFormatTime = "HH:mm";
  const dispatch = useDispatch();

  // Récupérer reduxActivity depuis Redux
  const reduxActivity = useSelector((state) => state.activities.activity);

  // États locaux pour les périodes et horaires (objets Dayjs)
  const [registrationStartDate, setRegistrationStartDate] = useState(null);
  const [registrationEndDate, setRegistrationEndDate] = useState(null);
  const [activityStartDate, setActivityStartDate] = useState(null);
  const [activityEndDate, setActivityEndDate] = useState(null);
  const [dailyStartTime, setDailyStartTime] = useState(null);
  const [dailyEndTime, setDailyEndTime] = useState(null);
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  // Synchronisation des valeurs par défaut depuis reduxActivity
  useEffect(() => {
    if (reduxActivity) {
      setRegistrationStartDate(
        reduxActivity.registrationStart ? dayjs.utc(reduxActivity.registrationStart) : null
      );
      setRegistrationEndDate(
        reduxActivity.registrationEnd ? dayjs.utc(reduxActivity.registrationEnd) : null
      );
      setActivityStartDate(
        reduxActivity.activityStart ? dayjs.utc(reduxActivity.activityStart) : null
      );
      setActivityEndDate(
        reduxActivity.activityEnd ? dayjs.utc(reduxActivity.activityEnd) : null
      );
      // Pour l'horaire quotidien, si les champs dailyStartTime/dailyEndTime existent, on les utilise,
      // sinon on extrait l'heure à partir de activityStart/activityEnd.
      setDailyStartTime(
        reduxActivity.dailyStartTime 
          ? parseTime(reduxActivity.dailyStartTime)
          : (reduxActivity.activityStart ? extractTimeUTC(reduxActivity.activityStart) : null)
      );
      setDailyEndTime(
        reduxActivity.dailyEndTime 
          ? parseTime(reduxActivity.dailyEndTime)
          : (reduxActivity.activityEnd ? extractTimeUTC(reduxActivity.activityEnd) : null)
      );
    }
  }, [reduxActivity]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (
      !registrationStartDate ||
      !registrationEndDate ||
      !activityStartDate ||
      !activityEndDate ||
      !dailyStartTime ||
      !dailyEndTime
    ) {
      setError('Veuillez remplir tous les champs.');
      return;
    }

    // Pour la période d'inscription, fixer l'heure à 10:00 (UTC) pour le début et 00:00 (UTC) pour la fin
    const regStart = registrationStartDate.hour(10).minute(0).second(0);
    const regEnd = registrationEndDate.hour(0).minute(0).second(0);

    // Construire les données à envoyer au back sous forme de chaînes
    const formData = {
      id: reduxActivity?.id,
      organizer_id: reduxActivity?.organizer_id,
      registrationStart: regStart.format(sendFormatDate),
      registrationEnd: regEnd.format(sendFormatDate),
      activityStartDate: activityStartDate.format(sendFormatDate),
      activityEndDate: activityEndDate.format(sendFormatDate),
      dailyStartTime: dailyStartTime.format(displayFormatTime),
      dailyEndTime: dailyEndTime.format(displayFormatTime),
    };

    try {
      const response = await api.post("organizer/update-periode", formData);
      setSuccessMsg(response.data.message);
      dispatch({
        type: constants.GET_ACTIVITY,
        payload: response.data.activity,
      });
    } catch (err) {
      setError("Erreur lors de la mise à jour des périodes.");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {error && <Alert severity="error">{error}</Alert>}
          {successMsg && <Alert severity="success">{successMsg}</Alert>}

          {/* Période d'inscription */}
          <Typography variant="h6" component="h2">
            Période d'inscriptions
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Début des inscriptions"
                format={displayFormatDate}
                value={registrationStartDate}
                onChange={(newValue) => setRegistrationStartDate(newValue)}
                inputFormat={displayFormatDate}
                slotProps={{
                  textField: { fullWidth: true, size: 'small' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Fin des inscriptions"
                format={displayFormatDate}
                value={registrationEndDate}
                onChange={(newValue) => setRegistrationEndDate(newValue)}
                inputFormat={displayFormatDate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    inputProps: { style: { textAlign: 'left' } },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Typography variant="caption" color="text.secondary">
            Les inscriptions débutent toujours à 10:00 et se terminent à 00:00.
          </Typography>

          {/* Période de l'activité */}
          <Typography variant="h6" component="h2">
            Période de l'activité
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Début de l'activité"
                format={displayFormatDate}
                value={activityStartDate}
                onChange={(newValue) => setActivityStartDate(newValue)}
                inputFormat={displayFormatDate}
                slotProps={{
                  textField: { fullWidth: true, size: 'small' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Fin de l'activité"
                format={displayFormatDate}
                value={activityEndDate}
                onChange={(newValue) => setActivityEndDate(newValue)}
                inputFormat={displayFormatDate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    inputProps: { style: { textAlign: 'left' } },
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Horaire quotidien de l'activité */}
          <Typography variant="h6" component="h2">
            Horaire quotidien
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TimePicker
                label="Heure de début"
                value={dailyStartTime}
                onChange={(newValue) => setDailyStartTime(newValue)}
                format={displayFormatTime}
                slotProps={{
                  textField: { fullWidth: true, size: 'small' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TimePicker
                label="Heure de fin"
                value={dailyEndTime}
                onChange={(newValue) => setDailyEndTime(newValue)}
                format={displayFormatTime}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    inputProps: { style: { textAlign: 'left' } },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
            Enregistrer les périodes d'activité
          </Button>
        </Box>
    </LocalizationProvider>
  );
}