import React, { useState } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Button,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import ActivityDrawer from './formActivity/components/ActivityDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { constants } from '../../../auth/_constants/constants';

const ActivityManagement = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filterType, setFilterType] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { section, tab, tab2 } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Récupérer la liste des activités depuis Redux
    const activities = useSelector((state) => state.activities.activities) || [];

    // Filtrage des activités
    const filteredActivities = activities.filter((activity) => {
        return (
            activity.activityName.toLowerCase().includes(searchQuery.toLowerCase()) &&
            (filterType ? activity.activityType === filterType : true) &&
            (filterStatus ? (activity.isPublished ? 'Published' : 'Draft') === filterStatus : true)
        );
    });

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleTypeFilterChange = (e) => {
        setFilterType(e.target.value);
    };

    const handleStatusFilterChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleNewActivityClick = () => {
        navigate(`/organizer/${section}/${tab}/${tab2}/new`);
        setDrawerOpen(true);
    };

    const handleEditeActivityClick = (id) => {
        // Vérifier que l'id est défini et que l'activité existe dans la liste
        const currentActivity = activities.find((activity) => activity.id === id);
        if (!currentActivity) {
            console.error("Activité non trouvée pour l'id:", id);
            return;
        }
        dispatch({
            type: constants.GET_ACTIVITY,
            payload: currentActivity,
        });
        navigate(`/organizer/${section}/${tab}/${tab2}/edit/${id}`);
        setDrawerOpen(true);
    };

    const handleViewActivityClick = (id) => {
        // Vérifier que l'id est défini et que l'activité existe dans la liste
        const currentActivity = activities.find((activity) => activity.id === id);
        if (!currentActivity) {
            console.error("Activité non trouvée pour l'id:", id);
            return;
        }
        dispatch({
            type: constants.GET_ACTIVITY,
            payload: currentActivity,
        });
        navigate(`/organizer/${section}/${tab}/${tab2}/view/${id}`);
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        navigate(`/organizer/${section}/${tab}/${tab2}`);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: '0.9rem' }}>
                Liste des Activités
            </Typography>

            {/* Zone de recherche et filtres */}
            <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
                <TextField
                    label="Recherche"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    variant="outlined"
                    size="small"
                />
                <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={filterType}
                        onChange={handleTypeFilterChange}
                        label="Type"
                    >
                        <MenuItem value="">Tous</MenuItem>
                        <MenuItem value="Workshop">Atelier</MenuItem>
                        <MenuItem value="Conference">Conférence</MenuItem>
                        <MenuItem value="Seminar">Séminaire</MenuItem>
                        <MenuItem value="activiteparascolaire">Activité parascolaire</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" size="small" >
                    <InputLabel>Statut</InputLabel>
                    <Select
                        value={filterStatus}
                        onChange={handleStatusFilterChange}
                        label="Statut"
                    >
                        <MenuItem value="">Tous</MenuItem>
                        <MenuItem value="Published">Published</MenuItem>
                        <MenuItem value="Draft">Draft</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleNewActivityClick}
                    size="small"
                >
                    Nouvelle Activité
                </Button>
            </Box>

            {/* Tableau des activités avec mise en évidence sur hover */}
            <TableContainer component={Paper} sx={{ width: '100%' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={{ height: 40 }}>
                            <TableCell sx={{ fontSize: '0.8rem' }}>Nom</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem' }}>Type</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem' }}>Statut</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem' }}>Début</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem' }}>Fin</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem' }}>Lieu</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem' }}>Inscrits</TableCell>
                            <TableCell sx={{ fontSize: '0.8rem' }}><VisibilityOutlined fontSize="small" /></TableCell>
                            <TableCell sx={{ fontSize: '0.8rem' }}><EditIcon fontSize="small" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredActivities.map((activity) => (
                            <TableRow
                                key={activity.id}
                                sx={{
                                    height: 40,
                                    '&:hover': {
                                        backgroundColor: 'rgba(25, 118, 210, 0.1)',
                                    },
                                }}
                            >
                                <TableCell sx={{ fontSize: '0.8rem' }}>{activity.activityName}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>{activity.activityType}</TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>
                                    {activity.isPublished ? "Published" : "Draft"}
                                </TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>
                                    {activity.activityStart
                                        ? new Date(activity.activityStart).toLocaleDateString()
                                        : '-'}
                                </TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>
                                    {activity.activityEnd
                                        ? new Date(activity.activityEnd).toLocaleDateString()
                                        : '-'}
                                </TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>
                                    {`${activity.streetName} ${activity.streetNumber}, ${activity.city}`}
                                </TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>
                                    {activity.maxRegistrations ? `A/${activity.maxRegistrations}` : '-'}
                                </TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>
                                    <IconButton
                                        onClick={() => handleViewActivityClick(activity.id)}
                                        size="small"
                                    >
                                        <VisibilityOutlined fontSize="small" />
                                    </IconButton>
                                </TableCell>
                                <TableCell sx={{ fontSize: '0.8rem' }}>
                                    <IconButton
                                        onClick={() => handleEditeActivityClick(activity.id)}
                                        size="small"
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ActivityDrawer open={drawerOpen} onClose={handleDrawerClose} />
        </Box>
    );
};

export default ActivityManagement;