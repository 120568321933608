import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment, Alert, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import api from '../utils/api';
import { useDispatch } from 'react-redux';
import { constants } from '../auth/_constants/constants';

export default function UpdateEmail() {
    const [emailData, setEmailData] = useState({
        currentPassword: '',
        newEmail: '',
        existingEmail: JSON.parse(localStorage.getItem("user"))?.email || '', // Récupération de l'email actuel
    });

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState('error');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmailData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: '' }));
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const handleSave = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        const newErrors = {};

        if (!emailData.newEmail.includes('@')) {
            newErrors.newEmail = 'Veuillez entrer une adresse email valide.';
        }

        if (!emailData.currentPassword) {
            newErrors.currentPassword = 'Le mot de passe actuel est requis.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // Ouvrir le modal pour confirmation
        setIsDialogOpen(true);
    };

    const handleConfirm = async () => {
        const userData = {
            currentPassword: emailData.currentPassword,
            newEmail: emailData.newEmail,
        };

        try {
            setLoading(true);
            setMessage(null);
            const response = await api.post('/update-user-email', userData);
            if (response.status === 200) {
                setMessage(response.data.message);
                setMessageType('success');
                dispatch({
                    type: constants.UPDATE_USER_EMAIL,
                    payload: {
                        email: emailData.newEmail,
                    },
                });

                // Mise à jour du localStorage
                const currentUser = JSON.parse(localStorage.getItem("user"));
                currentUser.email = emailData.newEmail;
                localStorage.setItem("user", JSON.stringify(currentUser));

                // Réinitialiser le formulaire
                setEmailData({
                    currentPassword: '',
                    newEmail: '',
                    existingEmail: emailData.newEmail,
                });
            } else {
                setMessage('Une erreur est survenue.');
                setMessageType('error');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const serverErrors = error.response.data.errors;
                const formattedErrors = {};
                Object.keys(serverErrors).forEach((key) => {
                    formattedErrors[key] = serverErrors[key][0]; // Prend le premier message d'erreur pour chaque champ
                });
                setErrors(formattedErrors);
            } else if (error.response && error.response.data && error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Erreur interne du serveur.');
            }
            setMessageType('error');
        } finally {
            setLoading(false);
            setIsDialogOpen(false);
        }
    };

    return (
        <Card sx={{ padding:"20px", boxShadow: 1 }}>
            {message && (
                <Alert severity={messageType} sx={{ mb: 2 }}>
                    {message}
                </Alert>
            )}

            <form onSubmit={handleSave}>
                {/* Champ masqué pour l'email existant */}
                <TextField
                    label="Adresse email actuelle"
                    name="existingEmail"
                    type="email"
                    value={emailData.existingEmail}
                    fullWidth
                    margin="normal"
                    sx={{ display: 'none' }} // Cache le champ visuellement
                    disabled
                    autoComplete="username" // Ajout pour les bonnes pratiques
                />

                <TextField
                    label="Mot de passe actuel"
                    name="currentPassword"
                    type={showPassword ? 'text' : 'password'}
                    value={emailData.currentPassword}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.currentPassword}
                    helperText={errors.currentPassword}
                    autoComplete="current-password" // Respect des bonnes pratiques
                    slotProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleTogglePasswordVisibility}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    label="Nouvelle adresse email"
                    name="newEmail"
                    type="email"
                    value={emailData.newEmail}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.newEmail}
                    helperText={errors.newEmail}
                    autoComplete="email"
                />
                <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{ mt: 2 }}
                    fullWidth
                >
                    {loading ? <CircularProgress size={24} /> : 'Sauvegarder l\'adresse email'}
                </Button>
            </form>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>Confirmer le changement d'email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Un email de confirmation sera envoyé à <strong>{emailData.newEmail}</strong>. 
                        Vous devez cliquer sur le lien dans un délai de <strong>60 minutes</strong> pour valider ce changement. 
                        Tant que cela n'est pas fait, votre adresse email actuelle restera active.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Confirmer'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}