import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Modal,
  Paper,
  Grid,
  Alert,
} from '@mui/material';

// Définition des champs à afficher avec leur label, clé et obligation
const fieldDefinitions = [
  { key: 'activityName', label: "Nom de l'activité", required: true },
  { key: 'activityType', label: "Type d'activité", required: true },
  { key: 'description', label: 'Description', required: true },
  { key: 'street', label: 'Adresse', required: true },
  { key: 'zipCode', label: 'Code postal', required: true },
  { key: 'city', label: 'Ville', required: true },
  { key: 'country', label: 'Pays', required: true },
];

const OptionDisplayOn = ({ activity = {} }) => {
  const [modalOpen, setModalOpen] = useState(false);

  // Calculer les informations pour chaque champ en utilisant l'opérateur optionnel
  const fields = fieldDefinitions.map(field => {
    const value = activity?.[field.key] || '';
    // Si le champ est requis, le texte est en vert s'il est renseigné, sinon en rouge
    const color = field.required ? (value.trim() !== '' ? 'green' : 'red') : 'inherit';
    return { ...field, value, color };
  });

  // L'activité est publable si tous les champs obligatoires sont renseignés (aucun en rouge)
  const canPublish = fields.every(field => !field.required || (field.required && field.value.trim() !== ''));

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Récapitulatif des informations de l'activité
      </Typography>

      {fields.map(field => (
        <Box key={field.key} sx={{ mb: 1 }}>
          <Typography variant="body1" sx={{ color: field.color }}>
            {field.label}: {field.value ? field.value : 'Non renseigné'}
          </Typography>
        </Box>
      ))}

      <Box sx={{ mt: 2 }}>
        {canPublish ? (
          <Alert severity="success">
            Toutes les informations obligatoires sont renseignées. L'activité peut être publiée.
          </Alert>
        ) : (
          <Alert severity="error">
            Des informations obligatoires sont manquantes. L'activité ne peut pas être publiée.
          </Alert>
        )}
      </Box>

      <Button variant="outlined" onClick={handleOpenModal} sx={{ mt: 2 }}>
        Aperçu de l'activité
      </Button>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 500 },
            bgcolor: 'background.paper',
            p: 3,
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Aperçu de l'activité
          </Typography>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {activity?.activityName || "Nom de l'activité"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Type : {activity?.activityType || "Non renseigné"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {activity?.description || "Pas de description"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Adresse : {activity?.street ? `${activity.street}, ` : ''}{activity?.zipCode ? `${activity.zipCode}, ` : ''}{activity?.city ? `${activity.city}, ` : ''}{activity?.country || ''}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Button onClick={handleCloseModal} variant="contained" fullWidth sx={{ mt: 2 }}>
            Fermer l'aperçu
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default OptionDisplayOn;