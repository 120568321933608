import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Card, CardContent, CardActions, Modal, TextField, Select, MenuItem, FormControl, InputLabel, Paper } from '@mui/material';
import api from '../utils/api'; // Import de l'instance Axios configurée

const PrivatePage = () => {
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [newCar, setNewCar] = useState({
        id: null,
        brand: '',
        color: '',
        top_speed: '',
        engine_type: '',
        horsepower: '',
        transmission: '',
    });

    useEffect(() => {
        let isMounted = true;

        const fetchCars = async () => {
            const accessToken = localStorage.getItem('access_token');

            if (!accessToken) {
                console.error('Pas de jeton d\'accès disponible pour récupérer les voitures.');
                return;
            }

            try {
                const response = await api.get('cars');

                if (isMounted) {
                    setCars(response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des voitures :', error);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchCars();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleOpenModal = (car = {}) => {
        setNewCar({
            id: car.id || null,
            brand: car.brand || '',
            color: car.color || '',
            top_speed: car.top_speed || '',
            engine_type: car.engine_type || '',
            horsepower: car.horsepower || '',
            transmission: car.transmission || '',
        });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewCar({
            id: null,
            brand: '',
            color: '',
            top_speed: '',
            engine_type: '',
            horsepower: '',
            transmission: '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewCar({ ...newCar, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
            console.error('Pas de jeton d\'accès disponible pour ajouter ou modifier une voiture.');
            return;
        }

        try {
            if (newCar.id) {
                await api.put(`cars/${newCar.id}`, newCar, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
            } else {
                await api.post('cars', newCar, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
            }

            const response = await api.get('cars', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setCars(response.data);
            handleCloseModal();
        } catch (error) {
            console.error('Erreur lors de l\'ajout ou de la modification de la voiture :', error);
        }
    };

    const handleDelete = async (id) => {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
            console.error('Pas de jeton d\'accès disponible pour supprimer une voiture.');
            return;
        }

        try {
            await api.delete(`cars/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            const response = await api.get('cars', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setCars(response.data);
        } catch (error) {
            console.error('Erreur lors de la suppression de la voiture :', error);
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden'}}>
            {/* Colonne gauche : Liste des voitures */}
            <Box 
                sx={{ 
                    flex: 2, 
                    overflowY: 'auto', 
                    pr: 2, 
                    scrollbarWidth: 'none', // Masque la barre de défilement pour les navigateurs compatibles
                    '&::-webkit-scrollbar': { display: 'none' } // Masque la barre de défilement pour Webkit
                }}
            >
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Liste des Voitures ({cars.length})
                </Typography>
                {loading ? (
                    <Typography variant="body1">Chargement...</Typography>
                ) : (
                    cars.map((car) => (
                        <Card key={car.id} sx={{ mb: 2, borderRadius: 2, boxShadow: 2 }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{car.brand}</Typography>
                                <Typography>Couleur : {car.color}</Typography>
                                <Typography>Vitesse max : {car.top_speed} km/h</Typography>
                                <Typography>Type de moteur : {car.engine_type}</Typography>
                                <Typography>Puissance : {car.horsepower} HP</Typography>
                                <Typography>Transmission : {car.transmission}</Typography>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'space-between' }}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => handleOpenModal(car)}
                                >
                                    Modifier
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDelete(car.id)}
                                >
                                    Supprimer
                                </Button>
                            </CardActions>
                        </Card>
                    ))
                )}
            </Box>

            {/* Colonne droite : Recherche et ajout */}
            <Box sx={{ flex: 1 }}>
                <Paper sx={{ padding: 2, position: 'sticky', top: 0, borderRadius: 2, boxShadow: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Options
                    </Typography>
                    {/* Recherche */}
                    <TextField
                        label="Recherche"
                        variant="outlined"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    {/* Bouton Ajouter */}
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleOpenModal()}
                    >
                        Ajouter une Voiture
                    </Button>
                </Paper>
            </Box>

            {/* Modal pour ajouter ou modifier une voiture */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6">
                        {newCar.id ? 'Modifier une Voiture' : 'Ajouter une Voiture'}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Marque"
                            name="brand"
                            value={newCar.brand}
                            onChange={handleChange}
                            fullWidth
                            required
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Couleur"
                            name="color"
                            value={newCar.color}
                            onChange={handleChange}
                            fullWidth
                            required
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Vitesse max"
                            name="top_speed"
                            value={newCar.top_speed}
                            onChange={handleChange}
                            fullWidth
                            required
                            type="number"
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Type de moteur</InputLabel>
                            <Select
                                name="engine_type"
                                value={newCar.engine_type}
                                onChange={handleChange}
                            >
                                <MenuItem value="Petrol">Petrol</MenuItem>
                                <MenuItem value="Diesel">Diesel</MenuItem>
                                <MenuItem value="Electric">Electric</MenuItem>
                                <MenuItem value="Hybrid">Hybrid</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Puissance"
                            name="horsepower"
                            value={newCar.horsepower}
                            onChange={handleChange}
                            fullWidth
                            required
                            type="number"
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Transmission</InputLabel>
                            <Select
                                name="transmission"
                                value={newCar.transmission}
                                onChange={handleChange}
                            >
                                <MenuItem value="Manual">Manual</MenuItem>
                                <MenuItem value="Automatic">Automatic</MenuItem>
                            </Select>
                        </FormControl>
                        <Button variant="contained" type="submit" sx={{ mr: 2 }}>
                            Sauvegarder
                        </Button>
                        <Button variant="outlined" onClick={handleCloseModal}>
                            Annuler
                        </Button>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
};

export default PrivatePage;