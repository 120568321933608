import { constants } from "../auth/_constants/constants";

const initialState = {
    organizers: [],
    organizer: null,
    error: null,
};

export default function organizersReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_ORGANIZERS:
            return {
                ...state,
                organizers: action.payload || [],
                error: null,
            };
        case constants.NEW_ORGANIZER:
            return {
                ...state,
                organizers: [...state.organizers, action.payload.data],
                error: null,
            };
            case constants.UPDATE_ORGANIZER:
                return {
                  ...state,
                  organizer: action.payload.data, // Remplace entièrement l'objet organizer
                  organizers: state.organizers.map((organizer) =>
                    organizer.id === action.payload.data.id ? action.payload.data : organizer
                  ),
                  error: null,
                };
        case constants.GET_ORGANIZER:
            return {
                ...state,
                organizer: action.payload,
                error: null,
            };
        case constants.UPDATE_LOGO:
            return {
                ...state,
                organizer: {
                    ...state.organizer,
                    logo: action.payload.logo, // Met à jour l'avatar
                },
                organizers: state.organizers.map((organizer) =>
                    organizer.id === action.payload.id ? { ...organizer, logo: action.payload.logo } : organizer // Met l'avatar à null si l'ID correspond
                )
            };
        case constants.REMOVE_LOGO:
            return {
                ...state,
                organizer: {
                    ...state.organizer,
                    logo: null, // Met à jour l'avatar
                },
                organizers: state.organizers.map((organizer) =>
                    organizer.id === action.payload.id ? { ...organizer, logo: null } : organizer // Met l'avatar à null si l'ID correspond
                )
                
            };
        case constants.GET_ORGANIZERS_ERR:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}