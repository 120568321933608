import React, { useState } from 'react';
import { 
    Avatar, 
    Button, 
    CssBaseline, 
    TextField, 
    Link, 
    Box, 
    Grid2, 
    Typography, 
    Alert,
    CircularProgress, // Import du spinner
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../components/functions/Functions';
import api from '../utils/api';
import { useTranslation } from 'react-i18next'; // Import pour les traductions
import LanguageSwitcher from '../lang/components/LanguageSwitcher';

const theme = createTheme();

export default function ForgetPassword() {
    const { t } = useTranslation(); // Hook pour utiliser les traductions
    const [email, setEmail] = useState('');
    const [formError, setFormError] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('error'); // Par défaut, l'alerte est une erreur
    const [isLoading, setIsLoading] = useState(false); // État pour gérer le spinner

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Affiche le spinner

        try {
            const response = await api.post('auth/forgot-password', {
                email: email,
            });

            setFormError(response?.data?.message || t('forgetPassword.successMessage'));
            setAlertSeverity('success'); // Définir l'alerte comme succès en cas de réussite
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || t('forgetPassword.errorSending');
                setFormError(errorMessage);
            } else {
                setFormError(t('forgetPassword.generalError'));
            }
            setAlertSeverity('error'); // Afficher l'erreur si la requête échoue
        } finally {
            setIsLoading(false); // Masque le spinner une fois la requête terminée
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid2 container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid2 
                    xs={12}
                    md={4}
                    elevation={6} 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',  // Centrage vertical
                        alignItems: 'center',  // Centrage horizontal
                        mx: 'auto',  // Centrage horizontal complet
                        maxWidth: '350px',  // Limite la largeur maximale
                        width: '100%',  // Assure que la largeur soit adaptative
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            p: 4,
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'transparent', width: 56, height: 56 }}> {/* Ajustez la taille de l'avatar si nécessaire */}
                            <img 
                                src="https://www.i-kiddy.com/images/i-kiddy-logo.png" 
                                alt={t('common.logoAlt')} 
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
                            />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t('forgetPassword.title')}
                        </Typography>

                        {formError && (
                            <Alert  severity={alertSeverity} sx={{ width: '100%', mt: 2 }}>
                                {formError}
                            </Alert>
                        )}

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('forgetPassword.emailLabel')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                size="small"  // Taille réduite pour les champs
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} // Mettre à jour l'email
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 1.2 }}  // Réduction du padding vertical
                                disabled={isLoading} // Désactive le bouton pendant le chargement
                            >
                                {isLoading ? (
                                    <CircularProgress size={24} color="inherit" /> // Affiche le spinner
                                ) : (
                                    t('forgetPassword.sendButton') // Texte du bouton
                                )}
                            </Button>
                            
                            <Grid2 container sx={{ justifyContent: 'space-between' }}>
                                <Grid2>
                                    <Link href="/login" variant="body2" sx={{ textDecoration: 'none' }}>
                                        {t('forgetPassword.loginLink')}
                                    </Link>
                                </Grid2>
                                <Grid2>
                                    <Link href="/register" variant="body2" sx={{ textDecoration: 'none' }}>
                                        {t('forgetPassword.registerLink')}
                                    </Link>
                                </Grid2>
                            </Grid2>
                        </Box>
                        <LanguageSwitcher/>
                        <Box sx={{ mt: 5, mb: 2 }}>
                            <Copyright />
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </ThemeProvider>
    );
}