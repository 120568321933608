import React, { useContext } from 'react';
import { Typography, Link, Box, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ThemeModeContext } from '../../index'; // Assurez-vous que le chemin est correct

export default function Copyright(props) {
  const { mode, toggleTheme } = useContext(ThemeModeContext);

  return (
    <Box {...props}>
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://yourwebsite.com/" sx={{ textDecoration: 'none' }}>
          i-Kiddy
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
        <IconButton onClick={toggleTheme} size="small" sx={{ p: 0 }}>
          {mode === 'dark' ? (
            <Brightness7Icon fontSize="small" />
          ) : (
            <Brightness4Icon fontSize="small" />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}