const fr = {
  login: {
    welcome: "Connexion",
    emailAddress: "Adresse e-mail",
    password: "Mot de passe",
    rememberMe: "Se souvenir de moi",
    signIn: "Se connecter",
    forgotPassword: "Mot de passe oublié ?",
    dontHaveAccount: "Inscrivez-vous",
    invalidEmail: "Adresse e-mail invalide",
    validationError: "Veuillez entrer des informations valides.",
    loginSuccess: "Connexion réussie !",
    loginError: "Une erreur est survenue. Veuillez réessayer.",
    resendVerification: "Cliquez ici pour renvoyer l'email de vérification !",
    resendVerificationPrompt: "Votre adresse e-mail n’a pas encore été confirmée !",
    verificationLimitReached: "Vous avez atteint le nombre maximal de tentatives de vérification. Contactez le support.",
    loading: "Chargement..."
  },
  register: {
    title: "Inscription",
    firstName: "Prénom",
    lastName: "Nom",
    gender: "Genre",
    female: "Femme",
    male: "Homme",
    other: "Autre",
    birthDate: "Date de naissance",
    emailAddress: "Adresse e-mail",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
    acceptTerms: "J'accepte ",
    termsLink:"les termes et conditions",
    required: "* Obligatoire",
    submit: "S'inscrire",
    alreadyHaveAccount: "Vous avez déjà un compte ? Connectez-vous",
    passwordMismatch: "Les mots de passe ne correspondent pas.",
    passwordError: "Le mot de passe doit comporter au moins 8 caractères, une majuscule, une minuscule et un chiffre.",
    formError: "Veuillez vérifier les erreurs dans le formulaire.",
    successMessage: "Inscription réussie ! Un email de confirmation a été envoyé.",
    unexpectedError: "Une erreur inattendue est survenue."
  },
  forgetPassword: {
    title: "Mot de passe oublié",
    emailLabel: "Adresse e-mail",
    sendButton: "Envoyer",
    successMessage: "Un lien de réinitialisation a été envoyé à votre adresse e-mail.",
    errorSending: "Erreur lors de l'envoi du lien de réinitialisation.",
    generalError: "Une erreur est survenue. Veuillez réessayer.",
    loginLink: "Connexion",
    registerLink: "Vous n'avez pas de compte ? Inscrivez-vous",
  },
  new_password : {
    title: "Réinitialiser votre mot de passe",
    emailHiddenLabel: "Adresse Email",
    passwordLabel: "Nouveau mot de passe",
    confirmPasswordLabel: "Confirmer le mot de passe",
    passwordError: "Le mot de passe doit comporter au moins 8 caractères, une majuscule, une minuscule et un chiffre.",
    confirmPasswordError: "Les mots de passe ne correspondent pas.",
    resetButton: "Réinitialiser le mot de passe",
    invalidLink: "Lien invalide ou incomplet.",
    resetSuccess: "Votre mot de passe a été réinitialisé avec succès. Vous serez redirigé vers la page de connexion dans 5 secondes.",
    resetError: "Erreur lors de la réinitialisation du mot de passe. Veuillez réessayer.",
    loginLink: "Connexion",
    signupLink: "Vous n'avez pas de compte ? Inscrivez-vous"
},
verifyEmail: {
  loading: "Vérification en cours...",
  successMessage: "Votre email a été vérifié avec succès. Vous allez être redirigé(e) vers la page de connexion.",
  errorMessage: "Une erreur est survenue lors de la vérification.",
  tokenMissing: "Le token est manquant dans l'URL.",
  expiredMessage: "Le token a expiré. Veuillez demander un nouveau lien de vérification.",
  resendButton: "Demander un nouveau lien",
},
choiceType: {
    title: "sélectionnez le type de compte que vous souhaitez créer",
    parentTitle: "compte parent",
    parentDescription: "Créez un compte parent pour inscrire vos enfants à des activités et événements.",
    parentButton: "choisir parent",
    organizerTitle: "compte organisateur",
    organizerDescription: "Créez un compte organisateur pour proposer et gérer des activités et événements pour enfants.",
    organizerButton: "choisir organisateur"
  },
  common: {
    loading: "Chargement...",
    error: "Une erreur est survenue. Veuillez réessayer.",
    submit: "Soumettre",
    cancel: "Annuler",
    save: "Enregistrer"
  }
};

export default fr;