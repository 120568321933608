import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function ChildDetailsModal({ name, childId, open, onClose }) {
    const children = useSelector((state) => state.kids.kids);

    // Trouver l'enfant correspondant à l'id fourni
    const selectedChild = children.find((child) => child.id === childId);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Détails de {name}</DialogTitle>
            <DialogContent dividers>
                {selectedChild ? (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {selectedChild.firstname} {selectedChild.lastname}
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                Date de naissance :
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {new Date(selectedChild.birthday).toLocaleDateString()}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                Genre :
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {selectedChild.gender}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                ID national :
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {selectedChild.national_id || "N/A"}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Typography variant="body2" color="error">
                        Impossible de trouver les détails de cet enfant.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
